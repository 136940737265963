import HTTP from "@/api_system";

export default {
	state: {
      orderId: '',
      orderServiceId: '',
			loadService: true
   },
   mutations: {
   		changeOrderId(state, newValue) {
        return (state.orderId = newValue);
      },
      changeOrderServiceId(state, value) {
        return state.orderServiceId = value;
      },
      changeLoadService(state, value) {
        return state.loadService = value;
      },
   },
   getters: {
      getOrderId(state) {
        return state.orderId;
      },
      getOrderServiceId(state) {
        return state.orderServiceId;
      },
			getLoadService(state) {
				return state.loadService;
			}
   },
	actions: {
		sendPedidos({ commit, getters }, paramsData) {
      return new Promise(async (resolve, reject) => {
				try {
					const result = await	HTTP.post(`services/app/OrdersServices/ApplyOrdersService`, paramsData, { headers: { Authorization: `Bearer ${getters.getterToken}` }})
					resolve(result)
				} catch (error) {
					reject(error)
				}
			})
		},
		CheckFinishedOrder({ getters }, paramsID) {
			return new Promise(async (resolve, reject) => {
				try {
					const result = await	HTTP.post(`services/app/Order/CheckFinishedOrder?orderId=${paramsID}`, {}, { headers: { Authorization: `Bearer ${getters.getterToken}` }})
					resolve(result)
				} catch (error) {
					reject(error)
				}
			})
		}
	 }
  };

<template>
  <div class="menuMobile">
    <nav id="navMenu">
      <div class="row navHeader">
        <img @click="fecha" src="@/assets/image/Logo3-59.png" class="logoNav" />
        <img
          @click="fecha"
          src="@/assets/image/icones/delete.png"
          class="removeMenu"
        />
      </div>
      <div class="linkContainer">
        <subMenu @fecha="fecha" :verifyAltura="verifyAltura"></subMenu>
      </div>
      <div class="footerButtons"></div>
    </nav>
  </div>
</template>

<script>
import subMenu from "@/components/header/subHeaderMobile.vue";
export default {
  components: {
    subMenu
  },
  props: ["defineTrue"],
  data() {
    return {
      homologa: false,
      notificationUser: null,
      altura: 0
    };
  },
  methods: {
    verifyAltura() {
      if (this.$store.getters.getUser.profile === 1) {
        let containerSub = document.querySelector("#subMenuMobile");
        containerSub.style.height = "240px";
      }
    },
    abre() {
      const menu = document.querySelector("#navMenu");
      menu.classList.add("open");
      this.verifyAltura();
    },
    fecha() {
      const menu = document.querySelector("#navMenu");
      menu.classList.remove("open");
      this.$emit("turnFalse");
    },
  },
  watch: {
    $route(x) {
      if (x.path !== this.$router.push) {
        this.fecha();
      }
    },
    defineTrue() {
      if (this.defineTrue === true) {
        this.abre();
      }
    }
  },
  mounted() {
    if (this.$store.getters.getUser.profile === 1) {
      let subMenu = document.querySelector(".linksCont");
      if (subMenu) subMenu.style.height = "40px";
    }
  },
  computed: {
    notificacaoLength() {
      //console.log(this.$store.getters.notifQtd)
      // this.notificationUser = this.$store.getters.notifQtd
      return this.$store.getters.notifQtd;
    }
  }
};
</script>

<style scoped>
.linkAndInputCont {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
}
.linksCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 20px;
  border-bottom: 1px #98c4e6 solid;
  flex-wrap: wrap;
}
.linksCont a {
  text-decoration: none;
  color: #959494 !important;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
}
.menuIcon {
  position: absolute;
  width: 7%;
  z-index: 99;
  left: 10px;
  top: 10px;
}
.logoNav {
  width: 15%;
  margin-left: 38%;
}
.navHeader {
  margin-top: 5%;
  margin-left: 5%;
}
#navMenu {
  z-index: 99999999999;
  min-height: 100vh;
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  transition: 0.2s ease-in-out;
  background-color: #fff;
}
a {
  margin-bottom: 3% !important;
  text-decoration: none;
}
.open {
  left: 0 !important;
}
.removeMenu {
  cursor: pointer;
  height: 15%;
  margin-left: 28%;
}
.notificationCircle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #f38235;
    left: 90%;
    position: absolute;
    border: 2px solid#fff;
    bottom: 94%;
}
.notificationSolici{
    top: -105%;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #f38235;
    right: -13.2%;
    position: absolute;
    border: 2px solid#fff;
}
.notificationSolici span{
  font-weight: 500;
  color: #fff;
  font-size: 8px;
}

@media screen and (min-width: 768px) {
  .menuMobile {
    display: none;
  }
}

.linkContainer {
  width: 50%;
}
.notificationCircle span {
  font-weight: 500;
  color: #fff;
  font-size: 8px;
}
@media screen and (max-width:767px){
  .linkContainer {
    width: 100%;
  }
}
</style>

import HTTP from '@/api_system'
import convertArray from '../../const/convertArray'
export default {
  state:{
    masterTenantCards: {
      load: false,
      error: false,
      result: {}
    },
    masterGraphicColumn: {
      load: true,
      error: false,
      result: {}
    },
    masterGraphicServiceSold: {
      load: true,
      error: false,
      result: []
    }
  },
  mutations:{
    set_masterTenantCards(state, val) {
      state.masterTenantCards = {
        load: val.load,
        result: val.result,
        error: val.error
      }
    },
    set_masterGraphicColumn(state, val) {
      state.masterGraphicColumn = {
        load: val.load,
        result: val.result,
        error: val.error
      }
    },
    set_masterGraphicServiceSold(state, val) {
      state.masterGraphicServiceSold = {
        load: val.load,
        result: val.result,
        error: val.error
      }
    }
  },
  actions:{
    GetIndicatorByMonth({commit, getters, dispatch}){
        return new Promise((resolve , reject)=>{
           HTTP.get(`services/app/Indicators/GetIndicatorByMonth`, {
              headers: { Authorization: `bearer ${getters.getterToken}` }
          }).then(response => {
              var retorno = convertArray(response.data.result, 'Mês')
              resolve(retorno)
          }).catch(error => {

              reject({ msg: 'Ops algo deu errado' })
          })

        })
    },
    GetScoreOfSalesman({commit, getters, dispatch}){
        return new Promise((resolve , reject)=>{
           HTTP.get(`services/app/Dashboard/GetScoreOfSalesman`, {
              headers: { Authorization: `bearer ${getters.getterToken}` }
          }).then(response => {
              var retorno = response.data.result
              resolve(retorno)
          }).catch(error => {

              reject({ msg: 'Ops algo deu errado' })
          })

        })
    },
    getFranqueadoInfo({ commit, getters, dispatch }) {
      HTTP.get(`services/app/Dashboard/GetDashboardFranchise`, {
        headers: { Authorization: `bearer ${getters.getterToken}` }
      }).then(response => {
        commit('set_userScore', response.data.result.franchise.userScore);
        commit("set_userOverallSold", response.data.result.franchise.userOverallSold);
        commit('set_differenceOverAll', response.data.result.franchise.differenceGoalOverAll);
        commit('set_differenceScoreTotal', response.data.result.franchise.differenceScoreTotal);
        commit('setTenantGoal', response.data.result.goalCurrent)
      }).catch(error => {

      })
    },
    GetDashboardMaster({ commit, getters, dispatch }, param) {
      commit('set_masterTenantCards',
        {
          result: {},
          load: true,
          error: false
      });
      HTTP.get(`services/app/Dashboard/GetDashboardMaster?byPercent=${param === undefined? true : false}`, {
          headers: { Authorization: `bearer ${getters.getterToken}` }
      }).then(response => {
        commit('set_masterTenantCards',
        {
          result: response.data.result,
          load: false,
          error: false
        });
        dispatch('getSolicitacao');
      }).catch(error => {
        commit('set_masterTenantCards',
        {
          result: {},
          load: false,
          error: true
        });
      })
    },
    GetDashboardMasterGraphicColumn({ commit, getters, dispatch }, params) {
      commit('set_masterGraphicColumn', {
          result: {},
          load: true,
          error: false
      });
      HTTP.get(`services/app/Dashboard/GetTenantGraphics?tenantId=` +
        `${params.tenantId}&Date=${params.date}`,
        {
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }).then(response => {
          commit('set_masterGraphicColumn', {
          result: response.data.result,
          load: false,
          error: false
      });
        }).catch(error => {
          commit('set_masterGraphicColumn',
          {
            result: {},
            load: false,
            error: true
        });
        })
    },
    GetDashboardServicesSold({ commit, getters, dispatch }, params) {
      commit('set_masterGraphicServiceSold', {
        result: {},
        load: true,
        error: false
        });
      HTTP.get(`services/app/Dashboard/GetServicesSold?tenantId=` +
        `${params.tenantId}&date=${params.date}&categoryId=${params.categoryId}`,
        {
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }).then(response => {
          commit('set_masterGraphicServiceSold', {
            result: response.data.result,
            load: false,
            error: false
          });
        }).catch(error => {
          commit('set_masterGraphicServiceSold', {
            result: {},
            load: false,
            error: true
          });
        })
    }

  },
  getters:{
    get_masterTenantCards(state) {
      return state.masterTenantCards;
    },
    get_masterGraphicColumn(state) {
      return state.masterGraphicColumn;
    },
    get_masterGraphicServiceSold(state) {
      return state.masterGraphicServiceSold;
    }
  },
}

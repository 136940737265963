<template>
    <modal
      modalId="modalImage"
      :load="false"
      :typeLg="`modal_md`"
      aria-hidden="true"
    >
      <img
        :src="this.image" alt 
        @mouseout="closeModalImage()"
      />
    </modal>
  </template>
  
  <script>
  export default {
    props: ['image'],
    components: {
      modal: () => import("@/components/modal/modalDefault.vue"),
    },
    methods: {
      closeModalInstrucoes() {
        $('#modalImage').modal('hide');
			},
      teste() {
        console.log('teste');
      }
    }
  };
  </script>
  
  <style scoped src="@/assets/style/modal.css"></style>
  
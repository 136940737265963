var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"instrucaoRef",staticClass:"row",attrs:{"tag":"div"}},[_c('hr',{staticClass:"w-100"}),_c('div',{staticClass:"col-md-12 px-0"},[_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-md-3"},[_c('p',{staticClass:"titulo_dash mb-0"},[_vm._v("Passo a passo")])]),_c('ValidationProvider',{staticClass:"col-md-5",attrs:{"tag":"div","rules":"required","name":"Titulo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('div',{staticClass:"form__group field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.instruct.title),expression:"instruct.title"}],staticClass:"form__field",attrs:{"type":"input","placeholder":"Titulo","name":"Titulo","id":"Titulo"},domProps:{"value":(_vm.instruct.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.instruct, "title", $event.target.value)}}}),_c('label',{staticClass:"form__label",attrs:{"for":"Titulo"}},[_vm._v("Titulo")])]),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-md-3 pl-0"},[_c('div',{staticClass:"box_image position-relative"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.instruct.image},on:{"error":_vm.errorImage2}}),_c('input',{staticClass:"btnAlteraFoto position-absolute",attrs:{"type":"file","id":"file","accept":"image/x-png, image/gif, image/jpeg","placeholder":"Alterar"},on:{"change":_vm.onChoseFile}})])]),_c('div',{staticClass:"col-md-9 px-0"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"Instruções"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.instruct.description),expression:"instruct.description"}],staticClass:"w-100 mb-0 px-2 py-2",domProps:{"value":(_vm.instruct.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.instruct, "description", $event.target.value)}}}),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-md-4 px-0"},[_c('span',{staticClass:"contador"},[_vm._v(_vm._s(_vm.instruct.description.length)+"/ 200")])]),_c('div',{staticClass:"col-md-4 ml-auto px-0 text-right"},[_c('a',{staticClass:"mb-0 p_destaque",class:_vm.instruct.spotlight ? 'p_destaque_active' : 'p_destaque',on:{"click":function($event){$event.preventDefault();return _vm.selectDestaque($event)}}},[_vm._v(" Destaque "),_c('i',{staticClass:"fas fa-star"})])])]),_c('div',{staticClass:"col-md-12 px-0 my-3"},[_c('button',{staticClass:"btn_file btn_default",on:{"click":function($event){$event.preventDefault();return _vm.convertArray()}}},[_vm._v(" Adicionar "),_c('i',{staticClass:"fas fa-plus-circle ml-1"})])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="px-1 menu">
    <div
      class="dropdown-item d-flex align-items-center mb-1 py-0"
      v-for="(item, index) in listaSup"
      :key="index"
      @click="changeRouterLink(item.name)"
    >
      <div class="col-md-12" >
        <p class="mb-0">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["listaSup"],
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    changeRouterLink(nameRouter) {

      nameRouter === 'Contas' ? this.componenteChangeStore('Users') : this.componenteChangeStore('Detran')

      this.$router.push(`/servico/${nameRouter}`)
    }
  }
};
</script>

<style scoped>
.dropdown-item {
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  height: 60px;
  color: #868686;
  font-size: 12px;
  white-space: normal;
  transition: all 0.2s linear;
  direction: ltr;
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: #f38235;
  color: #ffffff;
}
.menu {
  max-height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  direction: rtl;
  width: 200px;
}
.menu::-webkit-scrollbar {
  width: 5px;
  background: #fff;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #f38235;
  outline: 1px solid slategrey;
}
.menu::-webkit-scrollbar-track {
  background-color: #fff;
}
/* p {
  white-space: initial;
} */
</style>

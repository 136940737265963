export default {
    state: {

        validateCaixa: false,
        idCaixa: '',


    },
    mutations: {

        SET_VALIDATION(state, val) {
            state.validateCaixa = val
        },
        SET_CAIXAID(state, val) {
            state.idCaixa = val
        }

    },
    actions: {

    },
    getters: {


        validateCaixa: state => {
            return state.validateCaixa
        },


    },

}
import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";

import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/pt_BR.json";
import { configure } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
configure({
    classes: {
        valid: "is-valid",
        invalid: "is-invalid",
        dirty: ["is-dirty", "is-dirty"] // multiple classes per flag!
            // ...
    },
    inject: false
});

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});
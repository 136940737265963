import HTTP from '@/api_system'
export default {
  state: {
    userLogin: {},
    usersForLogin: [],
    userScore: "",
    tenantGoal: "",
    tenantScore: "",
    tenantDifferenceScore: "",
    tenantDifferenceOverall: "",
    token: "",
    timeLoggedIn: 0,
    userOverallSold: "",
    tenantOverallSold: "",
    differenceScoreTotal: '',
    differenceGoalOverAll: '',
    idAllUser: [],
    isDespathcer: false,
    isBBPartner: false
  },
  mutations: {
    // comprador
    set_differenceScoreTotal(state, val) {
      state.differenceScoreTotal = val
    },

    set_differenceOverAll(state, val) {
      state.differenceGoalOverAll = val
    },

    setTenantGoal(state, val) {
      state.tenantGoal = val
    },

    set_user(state, val) {
      state.userLogin = val;
    },

    set_usersForLogin(state, val) {
      state.usersForLogin = val;
    },

    set_token(state, val) {
      state.token = val;
    },

    set_userScore(state, val) {
      state.userScore = val;
    },

    set_tenantScore(state, val) {
      state.tenantScore = val;
    },

    set_tenantOverallSold(state, val) {
      state.tenantOverallSold = val;
    },

    set_userOverallSold(state, val) {
      state.userOverallSold = val;
    },

    SET_TIME_START(state, val) {
      state.timeLoggedIn = val;
    },

    set_tenantDifferenceScore(state, val){
      state.tenantDifferenceScore = val
    },

    set_tenantDifferenceOverall(state, val){
      state.tenantDifferenceOverall = val
    },

    set_id(state, val){
      state.idAllUser = val
    },

    set_isDespathcer(state, value){
      state.isDespathcer = value
    },

    set_isBBPartner(state, value){
      state.isBBPartner = value
    },
  },
  actions: {
    gerateReset({ commit, state, getters }) {
      var obj = {
        tenantId: getters.tenantRequire.tenantId === 0 ? "" : getters.tenantRequire.tenantId,
        userId: getters.getUser.id
      };
      return new Promise((resolve, reject) => {
        HTTP.post("services/app/Login/SendPasswordResetCode", obj)
          .then(response => {
            resolve(true);
          })
          .catch(response => {
            resolve(true);
          });
      });
    },
    validaCode: ({ getters }, resetcode) => {
      var obj = {
        tenantId: getters.tenantRequire.tenantId,
        userId: getters.getUser.id,
        resetcode: resetcode
      };

      return new Promise((resolve, reject) => {
        HTTP.post("services/app/Login/ValidateResetCode", obj)
          .then(response => {
            resolve(true);
          })
          .catch(response => {
            resolve(false);
          });
      });
    },
    trocaPassword({ getters }, password) {
      var obj = {
        tenantId: getters.tenantRequire.tenantId,
        userId: getters.getUser.id,
        password: password
      };
      return new Promise((resolve, reject) => {
        HTTP.post("services/app/Login/SendNewPassword", obj)
          .then(response => {
            resolve(true);
          })
          .catch(response => {
            resolve(false);
          });
      });
    },
    cpfForLogin(
      {
        commit,

        getters
      },
      val
    ) {
      return new Promise((resolve, reject) => {
        var replace = val
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "")
          .replace(/\s+/g, "");
        var url;
        if (getters.tenantRequire.tenantId === 0 || getters.tenantRequire.tenantId === undefined) {
          url = `services/app/Login/GetInformationsByCPFForLogin?cpf=${replace}`;
        } else {
          url = `services/app/Login/GetInformationsByCPFForLogin?cpf=${replace}&tenantId=${getters.tenantRequire.tenantId}`;
        }
        HTTP.get(url)
          .then(response => {
            if (response.data.result.length > 1) {   
              commit("set_usersForLogin", response.data.result);          
            } else {
              commit("set_user", response.data.result[0]);
            }
            resolve({ erro: false, result: response.data.result });
          })
          .catch(error => {
            resolve({ erro: true, message: error.response.data.error.message });
          });
      });
    },
    gerateToken({ commit, state, dispatch, getters }, senha) {
      return new Promise((resolve, reject) => {
        var postObj = {
          userNameOrEmailAddress: state.userLogin.userName,
          password: senha,

          rememberClient: true
        };
        if (getters.tenantRequire.tenantId != 0) {
          postObj.tenancyName = getters.tenantRequire.tenancyName;
        }

        HTTP.post("TokenAuth/Authenticate", postObj)
          .then(response => {
            resolve({ error: false });

            commit("set_token", response.data.result.accessToken);
            commit("set_id", [response.data.result.tenantId, response.data.result.userId]);
            commit('set_isDespathcer', response.data.result.isDespathcer)
            commit('set_isBBPartner', response.data.result.isBBPartner)

            dispatch("getAlert");
            dispatch("getAlertImportant");
          })
          .catch(err => {
            resolve({ error: true, message: err.response.data.error.details });
          });
      });
    },
    logoff({ commit, state }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
          commit("resetAll");
        }, 1000);
      });
    }
  },
  getters: {
    loginAutenticado(state) {
      return (
        state.userLogin &&
        !$.isEmptyObject(state.userLogin) &&
        state.token != ""
      );
    },
    getUser(state) {
      return state.userLogin;
    },

    getUsersForLogin(state) {
      return state.usersForLogin;
    },

    getterToken(state) {
      return state.token;
    },
    userScore(state) {
      return state.userScore;
    },
    tenantGoal(state) {
      return state.tenantGoal;
    },
    userOverallSold(state) {
      return state.userOverallSold;
    },
    tenantScore(state) {
      return state.tenantScore;
    },
    tenantOverallSold(state) {
      return state.tenantOverallSold;
    },

    differenceScoreTotal(state) {
      return state.differenceScoreTotal;
    },

    differenceGoalOverAllUser(state) {
      return state.differenceGoalOverAll;
    },

    tenantDifferenceScore(state) {
      return state.tenantDifferenceScore;
    },

    tenantDifferenceOverall(state) {
      return state.tenantDifferenceOverall;
    },

    getIdAllUser(state) {
      return state.idAllUser;
    },

    getBBPartner(state) {
      return state.isBBPartner;
    }
  }
};

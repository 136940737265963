<template>
	<div id="app">
		<Header v-if=" $route.meta.no_header" />

		<transition v-if="$route.meta.no_header" name="fade">
			<router-view :style="`min-height:${minHeight}px;`" />
		</transition>
		<div class="row" v-if="$route.meta.no_header && this.$store.getters.getUser.profile != 1">
			<transition name="vertical">
				<pedido class="col-md-2" v-if="$route.path !='/finalizar-servico' && $store.state.pedidos.pedidos !=''" />
			</transition>
		</div>
		<fluxo v-if="$route.name!= null && !$route.meta.no_header" />
		<notifications group="erros" position="bottom right" />
	</div>
</template>


<style src="./assets/style/app.css"></style>
<style src="./assets/style/transicoes.css"></style>

<style scoped>
</style>
<script>
	import Header from "@/components/header/_Header";
	import fluxo from "@/components/fluxo_login/_Fluxo";
	import pedido from "@/components/utils/pedidoFloat";
	export default {
		components: {
			Header,
			fluxo,
			pedido,
		},
		data() {
			return {
				minHeight: window.innerHeight - 140,
				logoSmall: "",
				hour: this.$store.state.login.timeLoggedIn,
			};
		},

		mounted() {
			this.$nextTick(() => {
				window.addEventListener("resize", this.getWindowHeight);
			});
			if (this.$store.state.login.token) {
			}
			this.timer(12);
		},
		watch: {
			$route: function () {
				if ($("#app").is(".overlay_custom")) {
					$(".dropdown-menu").fadeOut(500);
					$(".sub_menu").removeAttr("style");

					$(".navegation_item.active_item").removeClass("active_item");
					$("#app").removeClass("overlay_custom");
				}
			},
		},
		methods: {
			getWindowHeight() {
				var h = document.documentElement.clientHeight;

				this.minHeight = h - 140;
			},
			timer(howMuchHours) {
				var today = new Date();
				var hora = today.getHours();
				var valu = this.hour + howMuchHours * 60 * 60 * 1000;

				if (new Date().getTime() > valu) {
					this.$store.commit("resetAll");
					this.$router.push("/login");
				}
			},
		},
		computed: {
			autentic() {
				return this.$store.getters.validaAll;
			},
		},
	};
</script>

import store from '../index'
import { HubConnectionBuilder } from "@aspnet/signalr";

export default {
    state: {
      message: ''
    },
    mutations: {
      set_message(state, val) {
        state.message = val;
      }
    },
    actions: {
      initSignal({ commit }) {
        const connection = new HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(`${process.env.VUE_APP_ROOT_API}/signalr`)
          .build();
        connection.on("broadcastMessage", (name, message) => {
          if (store.state.signal.message == message) {
            commit("set_message", '');
            setTimeout(() => {
              commit("set_message", message);
            }, 5);
          }
          else {
            commit("set_message", message);
          }
        });

        async function start() {
          try {
            connection.serverTimeoutInMilliseconds = 180000;
            await connection.start();
          } catch (err) {
            console.log("desconect", err);
            setTimeout(start, 5000);
          }
        }
        connection.onclose(async () => {
          await start();
        });

        start();
      },
      initSignalJob({ commit }) {
        const connection = new HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(`${process.env.VUE_APP_ROOT_API}/Jobsignalr`)
          .build();
        connection.on("broadcastMessage", (name, message) => {
          if (store.state.signal.message == message) {
            commit("set_message", '');
            setTimeout(() => {
              commit("set_message", message);
            }, 5);
          }
          else {
            commit("set_message", message);
          }
        });

        async function start() {
          try {
            connection.serverTimeoutInMilliseconds = 180000;
            await connection.start();
          } catch (err) {
            console.log("desconect", err);
            setTimeout(start, 5000);
          }
        }
        connection.onclose(async () => {
          await start();
        });

        start();
      },
    },
    getters: {
      get_message: (state) => {
        return state.message
      }
    }
}

import Vue from 'vue'
import store from './../index.js'
import HTTP from '../../api_localservice.js';
import { API } from '../../api_financial';

export default {
    state: {
        usersFinancial: {
            user: undefined,
            cpf: undefined,
            cnpj: undefined,
            fone: undefined,
            email: undefined,
        },
        dataUser: null,
        codigoResponse: null,
        dataContas: [],
        codigos: undefined,
        valueParcelamento: 0,
        parcela: null,
        dataDebitos: [],
        dataPay: null
    },
    mutations: {
      usersFinancialChangeValue (state, newValue) {
        return state.usersFinancial = newValue
      },
      
      usersDataChangeValue (state, newValue) {
        return state.dataUser = newValue
      },
      
      codigoResponseDataNewValue (state, newValue) {
        return state.codigoResponse = newValue
      
      },
      dataContasNewValue (state, newValue) {
        return state.dataContas = newValue
      },
    
      codigoBarras (state, newValue) {
        return state.codigos = newValue
      },
  
      valueParcelamentoContas (state, newValue) {
        return state.valueParcelamento = newValue
      },

      parcelaX(state, newValue) {
        return state.parcela = newValue
      },

      dataDebitosNewValue (state, newValue) {
        return state.dataDebitos = newValue
      },
      
      dataPayNewValue (state, newValue) {
        return state.dataPay = newValue
      },
    },
    getters: {
      usersFinancialValues: (state) => {
        return state.usersFinancial
      },
      
      usersDataValues: (state) => {
        return state.dataUser
      },
      
      codigoResponseData: (state) => {
        return state.codigoResponse
      },
      
      dataContasGet: (state) => {
        return state.dataContas
      },
      
      codigoBarraGet: (state) => {
        return state.codigos
      },

      valueParcelamentoContasGet: (state) => {
        return state.valueParcelamento
      },
      
      parcelaXGet: (state) => {
        return state.parcela
      },

      dataDebitosGet: (state) => {
        return state.dataDebitos
      },
      
      dataPayGet: (state) => {
        return state.dataPay
      },
    },
    actions: {
      deleteDataContas({ commit, state }, newValue ) {

        let deleteValue = state.dataContas.filter(item => item !== newValue)
        
        commit('dataContasNewValue', deleteValue)

      },
      conectPinPadPay({ commit, state, dispatch }, data) {

        let auth = store.getters.getterToken
        let debitos = state.dataDebitos
        let dataJsonPay = state.dataPay

        HTTP.post('Transaction', data, {  
          headers :  {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json"
          }
        }
        ).then(res => {
        
          let { data } = res
          console.log(data)
          let { parcelas, terminal, tipo, valor } = dataJsonPay
          let dataJson = {
            parcelas,
            terminal,
            tipo,
            valor,
            debitos,
            "cartoes": [
              {
                "data": data[0].DateOperation,
                "adquirente": data[0].ClientCard,
                "numero": data[0].NumberCard,
                "autorizacao": data[0].Nsu,
                "nsu": data[0].Nsu,
                "valor": data[0].Value,
              }
            ],
          }
          
          console.log(dataJson)
          dispatch('paymentFinish', dataJson)
        }).catch(err => console.log(`Erro ${err}`))

      },
      cancelPinPadPay() {
        let auth = store.getters.getterToken
        HTTP.post('CancelationTransaction',{},{  
          headers :  {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json"
          }
        }).then(res => console.log(res, 'cancelado com sucesso')).catch(err => console.log(err))

      },
      paymentFinish({ commit, getters }, data ) {

        API.post(`consultas/${getters.codigoResponseData}/pagamentos`, data).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })

      },
      socketIoPinPad() {
        return
        const WebSocket = require('ws');
        var socket = new WebSocket("ws://127.0.0.1:9696/Spf");

        socket.onopen = function(e) {
          console.log('conectado')
        };

        socket.onmessage = function({data}) {
        console.log({data})
        };

        socket.onerror = function(error) {
          console.log(`[error] ${error.message}`);
        };
      }
    }
};
  
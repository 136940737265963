<template>
  <div id="pdfContent">
    <div id="header">
      Comprovante
      <div id="personalInfo" v-if="detail && detail.client">
        <!-- Informações pessoais -->
        <div class="name">Nome: {{getInformation(detail.client.name)}}</div>
        <div div class="birthdate">Nasc.: {{dateMask(getInformation(detail.client.birthDate))}}</div>
        <div class="cpf">CPF: {{cpfMask(getInformation(detail.client.cpf))}}</div>
        <div class="phone">Telefone: {{phoneMask(getInformation(detail.client.cellPhone))}}</div>
        <div class="email">E-Mail: {{getInformation(detail.client.email)}}</div>
      </div>
    </div>
    <div>
      <div id="serviceHeader">
        <div class="info">Data</div>
        <div class="info">Serviço</div>
        <div class="info">Valor</div>
    </div>
    <div id="serviceInfo">
      <div class="left">{{dateMask(getInformation(detail.dateOperation))}}</div>
      <div class="center">{{getInformation(detail.serviceName)}}</div>
      <div class="right">{{getInformation(detail.serviceValue)}}</div>
    </div>
    <div id="aditionalHeader">
      <div class="aditionalInfo">{{ getAditionalInformation(detail.dataTransaction, 'Placa') }}</div>
      <div class="aditionalInfo">{{ getAditionalInformation(detail.dataTransaction, 'Renavam') }}</div>
    </div>
    <div id="bottom">
      <div class="bottomInfo">Nº Pedido: {{getInformation(detail.orderId)}}</div>
    </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'PdfContent',
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  methods: {
    getInformation (info) {
      return info ?? 'S/Inf.'
    },
    getAditionalInformation (info, found) {
      const indice = (info?.findIndex(item =>
        item?.includes(found)
      )) ?? -1

      if (indice !== -1) {
        return this.getInformation(info[indice])
      }

      return ''
    }
  }
}
</script>

<style scoped>
#pdfContent {
  letter-spacing: 1.5px;
  word-spacing: 2.5px;
  font-family: "sans-serif";
  font-weight: bold;
  margin: auto;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
}

#header {
  text-align: center;
  font-size: 15px;
}

#personalInfo {
  text-align: left;
  margin: 0 auto;
  font-size: 12.5px;
  padding-top: 5px;
  width: 90%;
}

#personalInfo .name{
  width: 100%;
  margin-bottom: 2px;
}

#personalInfo .cpf,
.birthdate {
  width: 100%;
  margin-bottom: 2px;
}

#personalInfo .phone{
  width: 100%;
  margin-bottom: 2px;
}

#personalInfo .email{
  width: 100%;
  margin-bottom: 2px;
}

#serviceHeader {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 10.5px;
  text-align: center;
}

#serviceHeader .info {
  flex: 1;
  box-sizing: border-box;
  padding: 0 5px;
}

#serviceInfo {
  margin-top: 5px;
  display: flex;
  font-size: 10.5px;
}

#serviceInfo .left {
  text-align: center;
  flex: 1;
}

#serviceInfo .center {
  text-align: center;
  flex: 1;
}

#serviceInfo .right {
  text-align: center;
  flex: 1;
}

#aditionalHeader {
  padding-top: 5px;
  margin: 0 auto;
  width: 85%;
}

.aditionalInfo{
  font-size: 13px;
  text-align: left;
}

#bottom {
  padding-top: 5px;
  margin: 0 auto;
  width: 85%;
}

.bottomInfo{
  font-size: 13px;
  text-align: left;
}

</style>

<template>
	<div class="dropdown-menu sub_menu_sp px-1 py-0">
		<Menu @hoverMenuSup="hoverMenuSup" :suportes="suportes" />
		<subMenu :listaSup.sync="listaSup" />
	</div>
</template>

<script>
	import Menu from "./_MenuSuporte";
	import subMenu from "./_Sub_categoria_sup";
	export default {
		components: {
			subMenu,
			Menu,
		},
		data() {
			return {
				listaSup: [],
			};
		},
		mounted() {
			var self = this;
			$("#app").click(function (e) {
				if ($(e.target).is(".overlay_custom")) {
					self.listaSup = "";
				}
			});
		},
		methods: {
			hoverMenuSup(val) {
				if (val) {
					this.listaSup = val;
				} else {
					this.listaSup = "";
				}
			},
		},
		computed: {
			suportes() {
				return this.$store.state.suporte.suporteCategoria.filter((item) => {
					return item.listService != "";
				});
			},
		},
	};
</script>

<style scoped>
.dropdown-menu {
	left: 70%;
	top: 60px;
	background-color: transparent;
	padding-right: 15px;
	padding-left: 15px;
	margin-top: 0;

	border: none;
}
@media screen and (max-width: 767px){
	.dropdown-menu{
		opacity: 1;
		position: absolute!important;
		left: 0;
		top: 102%;
	}
}
</style>

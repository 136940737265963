<template>
  <div class="sub_menu d-flex align-items-center">
    <div class="container" id="containerId">
      <div class="col-md-12 mx-auto" id="belowCont">
        <div class="row align-items-center" id="allButtonsCont">
          <div
            class="col text-center mx-auto d-flex align-items-center justify-content-center"
            id="buttonMenu"
          >
            <a
              href="#"
              class="navegation_item align-items-center"
              @click.prevent="dropdownShow($event)"
            >
              <font-awesome-icon icon="fas-regular fa-id-card" />
              <p class="subMenuitemTxt" id="subMenuItemtxtId">Serviços</p>
              <i class="fas fa-chevron-down ml-3" style="font-size: 10px;"></i>
            </a>
          </div>
          <div
            class="col text-center mx-auto d-flex align-items-center justify-content-center noMaster"
            id="buttonMenu"
            v-if="$store.getters.getServiceDespachanteLength > 0"
          >
            <a
              href="#"
              class="navegation_item align-items-center"
              @click.prevent="dropdownShowServiceDespachante($event)"
            >
              <font-awesome-icon icon="fas-solid fa-car-on" />
              <p class="subMenuitemTxt">Despachante</p>
              <i class="fas fa-chevron-down ml-3" style="font-size: 10px;"></i>
            </a>
            <div
              v-if="getNotificationStatus.existNotification"
              class="notification"
            >
              <div
                class="notificationController"
                v-if="get_tenant.isDispatcher"
              >
                {{ getNotificationStatus.notificationsController }}
              </div>
              <div class="notificationAccompany" v-else></div>
            </div>
          </div>
          <!-- <div class="col text-center mx-auto d-flex align-items-center justify-content-center"  v-if="homologa">
						<a href="#" class="navegation_item align-items-center" @click.prevent="dropdownShowServiceMenu($event)">
							<icons_custom icone="parcela" bg="#868686" wi="25px" hei="25px" class="mr-1" />Financeiros
							<i class="fas fa-chevron-down ml-3" style="font-size: 10px;"></i>
						</a>
					</div> -->
          <div
            class="col text-center mx-auto navMenu"
            id="buttonMenu"
            v-if="cargo != 1"
          >
            <router-link
              :to="'/pedidos'"
              exact-active-class="active"
              class="navegation_item d-flex align-items-center justify-content-center "
            >
              <font-awesome-icon icon="fas-regular fa-clipboard-list" />
              <p class="subMenuitemTxt" id="subMenuItemtxtId">Pedidos</p>
            </router-link>
          </div>
          <div
            class="col text-center mx-auto noMaster"
            id="buttonMenu"
            v-if="cargo == 2"
          >
            <router-link
              :to="'/indicadores'"
              exact-active-class="active"
              class="navegation_item d-flex align-items-center justify-content-center"
            >
              <font-awesome-icon icon="fas-light fa-chart-simple" />
              <p class="subMenuitemTxt">Indicadores</p>
            </router-link>
          </div>
          <div
            class="col text-center mx-auto navMenu"
            id="buttonMenu"
            v-if="cargo != 1"
          >
            <router-link
              :to="'/caixa'"
              exact-active-class="active"
              class="navegation_item d-flex align-items-center justify-content-center"
            >
              <font-awesome-icon icon="fas-solid fa-dollar-sign" />
              <p class="subMenuitemTxt" id="subMenuItemtxtId">Caixa</p>
            </router-link>
          </div>
          <div
            v-if="this.$store.getters.getUser.profile != 1"
            class="col text-center mx-auto navMenu"
            id="buttonMenu"
          >
            <router-link
              :to="'/estoque'"
              exact-active-class="active"
              class="navegation_item d-flex align-items-center justify-content-center"
            >
              <font-awesome-icon icon="fas-solid fa-box-archive" />
              <p class="subMenuitemTxt">Estoque</p>
            </router-link>
          </div>
          <div
            class="col text-center mx-auto navMenu"
            id="buttonMenu"
            v-if="cargo == 2"
          >
            <router-link
              :to="'/despesas'"
              exact-active-class="active"
              class="navegation_item d-flex align-items-center justify-content-center "
            >
              <font-awesome-icon icon="fas-solid fa-dollar-sign" />
              <p class="subMenuitemTxt">Despesas</p>
            </router-link>
          </div>
          <div class="col text-center mx-auto navMenu" id="buttonMenu">
            <router-link
              :to="'/diario-de-bordo'"
              exact-active-class="active"
              class="navegation_item d-flex align-items-center justify-content-center"
            >
              <font-awesome-icon icon="fas-solid fa-book" />
              <p class="subMenuitemTxt">Diário de Bordo</p>
            </router-link>
          </div>
          <!-- <div
            class="col text-center mx-auto d-flex align-items-center justify-content-center"
            id="buttonMenu"
          >
            <a
              href="#"
              class="navegation_item align-items-center"
              @click.prevent="dropdownShowSup($event)"
            >
              <icons_custom
                icone="suporte"
                bg="#868686"
                wi="25px"
                hei="25px"
                class="mr-1"
              />
              Suporte
              <i class="fas fa-chevron-down ml-3" style="font-size: 10px;"></i>
            </a>
          </div> -->
          <dropdown ref="dropDown" @showModalDegustacaoDropdownServico="showModalDegustacaoDropdownServico"/>
          <dropdownSup ref="dropdownSup"/>
          <dropdownInstallment ref="dropdownInstallment" />
          <Dropdown_despachante ref="Dropdown_despachante" @showModalDegustacao="showModalDegustacao"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icons_custom from "@/components/utils/_Icon";
import dropdown from "./submenu/_Dropdown_sb";
import dropdownSup from "./submenu/_Dropdown_sup";
import dropdownInstallment from "./submenu/_Dropdown_installment";
import Dropdown_despachante from "./submenu/despachante/_Dropdown_despachante.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    icons_custom,
    dropdown,
    dropdownSup,
    dropdownInstallment,
    Dropdown_despachante
  },
  data() {
    return {
      homologa: false,
      dataMenu: [
        {
          name: "Parcelamentos",
          category: null,
          id: 1,
          listInstallment: [
            {
              name: "Contas",
              category: null,
              id: 1
            },
            {
              name: "Detran",
              category: null,
              id: 2
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.verifySubMenuProfile();
    if (this.$store.getters.getUser.profile === 1) {
      let subMenuCont = document.querySelectorAll("#containerId");
      if (subMenuCont[0]) subMenuCont[0].style.maxWidth = "300px";
    }

    this.$store.commit("menuInstallment", this.dataMenu);
    this.homologaShow();
  },
  watch: {
    $route: function() {
      this.$refs.dropDown.hoverMenu("");
      this.$refs.dropdownSup.hoverMenuSup("");
    }
  },
  methods: {
    verifySubMenuProfile() {
      if (this.$store.getters.getUser.profile == 1) {
        let cont = document.querySelector(".noMaster");
        if (cont) cont.style.display = "none";
      }
    },
    homologaShow() {
      let urlApi = process.env.VUE_APP_ROOT_API;

      if (urlApi === "//apiteste.pontofacil.fasters.com.br") {
        this.homologa = true;
      }
    },
    dropdownShow(e) {
      //! serviços
      if (this.$route.name === "acompanhar") {
        this.$store.commit("setCloseFilter", true);
      }

      if ($(".sub_menu_dp").css("display") == "flex") {
        this.$refs.dropDown.hoverMenu("");
        e.target.classList.remove("active_item");
        $(".sub_menu_dp").fadeOut(500);
        $("header").css({ "z-index": "3" });
        $(".sub_menu").removeAttr("style");

        $("#app").removeClass("overlay_custom");
      } else {
        this.removeOverMenuActive(
          "sub_menu_despachante",
          this.$refs.Dropdown_despachante,
          e
        );
        e.target.classList.add("active_item");

        $(".sub_menu").css({ "z-index": "1030" });
        $("header").css({ "z-index": "1031" });
        // $(".sub_menu_dp").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_dp").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });

        $("#app").addClass("overlay_custom");
      }
    },

    dropdownShowServiceMenu(e) {
      // finaceiros
      if ($(".sub_menu_installment").css("display") == "flex") {
        this.$refs.dropdownInstallment.dropdownInstallment("");
        e.target.classList.remove("active_item");
        $(".sub_menu_installment").fadeOut(500);
        $("header").css({ "z-index": "3" });
        $(".sub_menu").removeAttr("style");

        $("#app").removeClass("overlay_custom");
      } else {
        e.target.classList.add("active_item");
        $(".sub_menu").css({ "z-index": "1030" });
        $("header").css({ "z-index": "1031" });
        // $(".sub_menu_installment").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_installment").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });
        $("#app").addClass("overlay_custom");
      }
    },
    removeAll() {
      this.$refs.dropDown.hoverMenu("");
    },

    removeOverMenuActive(name, refsDrop, e) {
      if ($(`.${name}`).css("display") == "flex") {
        if (refsDrop.hoverMenu) {
          refsDrop.hoverMenu("");
        } else {
          refsDrop.hoverMenuSup("");
        }
        const links = document.querySelectorAll(".navegation_item");
        links.forEach(item => {
          item.classList.remove("active_item");
        });

        $(`.${name}`).fadeOut(500);
        $("header").css({ "z-index": "3" });
        $(".sub_menu").removeAttr("style");
        $("#app").removeClass("overlay_custom");
      }
    },

    dropdownShowServiceDespachante(e) {
      if ($(".sub_menu_despachante").css("display") == "flex") {
        this.$refs.Dropdown_despachante.hoverMenu("");
        e.target.classList.remove("active_item");
        $(".sub_menu_despachante").fadeOut(500);
        $("header").css({ "z-index": "3" });
        $(".sub_menu").removeAttr("style");
        $("#app").removeClass("overlay_custom");
        $(".menu").css({ left: "-155px" });
      } else {
        this.removeOverMenuActive("sub_menu_dp", this.$refs.dropdownSup, e);
        e.target.classList.add("active_item");

        $(".sub_menu").css({ "z-index": "1030" });
        $("header").css({ "z-index": "1031" });

        $(".sub_menu_despachante").css({ left: "140px" });
        // $(".sub_menu_despachante").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_despachante").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });

        $("#app").addClass("overlay_custom");
      }
    },
    dropdownShowSup(e) {
      // suporte

      if ($(".sub_menu_sp").css("display") == "flex") {
        this.$refs.dropdownSup.hoverMenuSup("");
        e.target.classList.remove("active_item");
        $(".sub_menu_sp").fadeOut(500);
        $("header").css({ "z-index": "3" });
        $(".sub_menu").removeAttr("style");

        $("#app").removeClass("overlay_custom");
      } else {
        e.target.classList.add("active_item");
        $(".sub_menu").css({ "z-index": "1030" });
        $("header").css({ "z-index": "1031" });
        // $(".sub_menu_sp").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_sp").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });

        $("#app").addClass("overlay_custom");
      }
    },
    removeAllSup() {
      this.$refs.dropdownSup.hoverMenuSup("");
    },
    closeDropDown(element) {},
    showModalDegustacao () {
      this.$emit("showModalDegustacao");
    },
    showModalDegustacaoDropdownServico() {
      this.$emit("showModalDegustacaoDropdownServico");
    }
  },
  computed: {
    ...mapGetters(["getNotificationStatus", "get_tenant"]),
    cargo() {
      return this.$store.state.login.userLogin.profile;
    }
  }
};
</script>

<style scoped>
#subMenuItemtxtId {
  min-width: 47px !important;
  max-width: 47px !important;
}
#buttonMenu {
  position: relative;
  height: 100%;
  overflow: hidden;
  min-width: 130px;
  max-width: 130px;
}
#buttonMenu a {
  height: 100%;
}
#buttonMenu::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.175rem;
  background: #f38235;
  left: 0;
  bottom: 0;
  transform: translateY(3px);
  transition: 0.3s ease;
}
#buttonMenu:hover::after {
  transform: translateY(0);
}

.container {
  height: 100%;
}
#belowCont {
  height: 100%;
}
#allButtonsCont {
  height: 100%;
  flex-wrap: unset;
  overflow-x: auto;
}
.sub_menu {
  background-color: #ffff;
  border-top: 1px #d3d3d3 solid;
  height: 45px !important;
  /* box-shadow: 10px 20px 20px 5px rgba(68, 68, 68, 0.6); */
  /* box-shadow: 0px 10px 20px 0px rgb(68, 68, 68); */
  z-index: 2;
}
.subMenuitemTxt {
  margin-bottom: 0 !important;
  padding-left: 3px;
  font-size: 12px;
  width: 70%;
}
#buttonMenu {
  width: 20%;
}

.navegation_item {
  color: #868686;

  display: inline-flex;
  font-weight: 400;
}
.navegation_item:hover {
  text-decoration: none;
  color: #2474b2;
}
.active {
  text-decoration: none;
  color: #2474b2;
}
.active.navegation_item .mask {
  background: #2474b2;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f38235', endColorstr='#e0a638',GradientType=0 ); /* IE6-9 */
}
.navegation_item:hover .mask {
  background: #2474b2;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f38235', endColorstr='#e0a638',GradientType=0 ); /* IE6-9 */
}
.active_item {
  text-decoration: none;
}
.active_item .mask {
  background: #2474b2;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f38235', endColorstr='#e0a638',GradientType=0 ); /* IE6-9 */
}

.notificationController {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13px;
  width: 13px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
  position: absolute;
  top: 3px;
  right: 1%;
}
#buttonMenu i {
  width: 5px;
  padding-left: 6px;
}

#buttonMenu p {
  min-width: 65px;
  max-width: 65px;
  padding-left: 5px;
  margin-right: 5px;
}
.subMenuitemTxt:first-child {
  min-width: 40px !important;
  max-width: 40px !important;
}
#buttonMenu svg {
  padding-left: 6px;
}
.notificationAccompany {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
  position: relative;
  top: -14px;
  right: 7px;
}
#allButtonsCont::-webkit-scrollbar {
  height: 0px; /* width of the entire scrollbar */
}

#allButtonsCont::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

#allButtonsCont::-webkit-scrollbar-thumb {
  background-color: #4991c9; /* color of the scroll thumb */
}
.container {
  width: 100%;
}

@media screen and (min-width: 1885px) {
  #containerId {
    max-width: 64% !important;
    margin-right: 21%;
  }
}
@media screen and (max-width: 1850px) {
  #containerId {
    max-width: 68% !important;
    margin-right: 19%;
  }
}
@media screen and (max-width: 1560px) {
  #containerId {
    max-width: 68% !important;
    margin-right: 19%;
  }
}
@media screen and (max-width: 1510px) {
  #containerId {
    max-width: 72% !important;
    margin-right: 17%;
  }
}
@media screen and (max-width: 1450px) {
  #containerId {
    max-width: 74% !important;
    margin-right: 15%;
  }
}
@media screen and (max-width: 1400px) {
  #containerId {
    max-width: 78% !important;
    margin-right: 13%;
  }
}
@media screen and (max-width: 1340px) {
  #containerId {
    max-width: 90% !important;
    margin-right: 6%;
  }
  #buttonMenu i {
    margin-left: 0 !important;
  }
  .subMenuitemTxt {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1142px) {
  #containerId {
    max-width: 92% !important;
    margin-right: 5%;
  }
}
@media screen and (max-width: 1120px) {
  #containerId {
    max-width: 97% !important;
    margin-right: 1%;
  }
}
@media screen and (max-width: 1081px) {
  #containerId {
    max-width: 100% !important;
    margin-right: 0%;
  }
}

@media screen and (max-width: 1100px) {
  #buttonMenu {
    min-width: 120px !important;
    max-width: 120px;
  }
}

@media screen and (max-width: 870px) {
  #buttonMenu {
    width: 15px;
  }
  .subMenuitemTxt {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 767px) {
  .navMenu {
    display: none !important;
  }
  #buttonMenu p {
    margin-right: 20px !important;
  }
  .subMenuitemTxt {
    font-size: 12px !important;
  }
  #allButtonsCont{
    display: flex;
    justify-content: center;
  }
  #buttonMenu {
    min-width: 150px;
    max-width: 150px;
  }
  .notificationController {
  font-size: 9px;
}
}
</style>

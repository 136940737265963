import HTTP from "@/api_system";
export default {
    state: {

    },
    mutations: {

    },
  actions: {
    CreateOrEditTerminalTef({ commit, getters }, obj) {
      return new Promise((resolve, reject) => {
        HTTP.post("services/app/TerminalTefTenant/CreateOrEdit", obj, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(
          response => {
            resolve({
              success: true,
              msg: response.data.result
            })
          }
        ).catch(error => {
          if (error.response.data.error.details != "") {
            reject({ success: false, msg: error.response.data.error.details })
          }
          else {
            reject({ success: false, msg: error.response.data.error.message })
          }
        });
      })
    },
    GetAllVinculados({ commit, getters }, params) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_ROOT_API}/api/services/app/TerminalTefTenant/GetAll?vinculed=${params.vinculed}`, {
          method: "GET",
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }).then(response => response.json())
          .then(response => {
            var result = response.result;
            resolve(result)
          }).catch(error => {
            reject({ erro: true, msg: error })
          })
      })
    },
    DeleteTerminalTef({ commit, getters }, params) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`services/app/TerminalTefTenant/Delete?Id=${params.id}`, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(response => {
          resolve({ success: true, msg: params.transaction ? 'Ops, ocorreu um problema durante a transição de cadastro de terminal e o mesmo foi excluido automaticamente, verifique e realize novamente o processo!' : 'Terminal Excluido com sucesso!' })
        }).catch(error => {
          reject({ success: false, msg: params.transaction ? 'Ops, ocorreu um erro durante a transição, repita o processo!' : 'Ocorreu um erro ao excluir o terminal!' })
        });
      })
    },
    InsertNewTerminalCode({ commit, getters }, obj) {
      return new Promise((resolve, reject) => {
        HTTP.post("services/app/TerminalTef/CreateTokenAndTerminal", obj, {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        }).then(
          response => {
            resolve({ success: true, msg: 'Terminal Cadastrado com Sucesso!' })
          }
        ).catch(error => {
          if (error.response == "") {
            reject({ erro: true, msg: "Não foi possivel estabelecer conexao, tente novamente." });
          }
          else if (error.response.data.error.details != "") {
            reject({ erro: true, msg: error.response.data.error.details.replace("Errors: [", "").replace("]", "") });
          }
          else {
            reject({ erro: true, msg: error.response.data.error.message });
          }
        });
      })
    }
    },
    getters: {

    }
}

<template>
	<div class="fixed-bottom mr-auto px-2" >
		<div :class="hoverText ? 'box bg-2' : 'box' " @mouseover="changeColor" @mouseleave="removeColor">
			<div>
        		<div class="text-center p-0" >
          		<router-link :to="'/finalizar-servico'" tag="a">
						<div v-if="!hoverText">
							<p class="texto mb-1">Pedido em andamento</p>
							<strong class="text-center"> #{{$store.state.pedidos.pedidos.id}}</strong>
						</div>
						<div v-else>
							<p class="textoFinish mb-1 p-0">Finalizar Pedido <img class="ml-2" src="@/assets/image/metas/arrowService.svg" alt="arrow.svg"> </p>
						</div>
          		</router-link>
        		</div>
      	</div>
    	</div>
  	</div>
</template>

<script>
import icons_custom from "./_Icon";
export default {
	components: {
		icons_custom
	},
    data() {
    	return {
        	hoverText: false
      }
    },
  	methods: {
   	changeColor() {
      	this.hoverText = true
    	},
		removeColor() {
			this.hoverText = false
		}
  }
};
</script>

<style scoped>
.box {
	bottom: 20px !important;
	cursor: pointer;
	border-radius: 12px;
	background: #F38235;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
	border-radius: 24px;
	height: 55px;
	width: 200px;
	margin-bottom: 25px;
	padding: 5px 0 10px 0;
	transition: .2s ease-in-out;
}

.texto {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #FFFFFF;
}

.textoFinish {
	color: #FFFFFF;
	font-weight: 600;
	font-size: 16px;
}

strong {
	color: #FFFFFF;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 20px;
}

a {
  	text-decoration: none;
}

.bg-2 {
	background-color: #25D366;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
}
</style>
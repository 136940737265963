<template>
  <div id="GRDS-manu" class="menu">
    <a
      class="dropdown-item d-flex align-items-center mb-0 py-0"
      href="#"
      v-for="(item, index) in servicos"
      :key="index"
      @mouseenter="hoverMenu(item)"
    >
      <!-- {{item}} -->
      <div
        class="col-md-10 d-flex align-items-center justify-content-between"
        id="itemInfo"
      >
        <p class="mb-0">{{ item.name || item.serviceName }}</p>
        <div v-if="notification" class="notificationControllerService"></div>
      </div>
      <div class="col-md-2 border_shadown">
        <i class="fas fa-chevron-right"></i>
      </div>
    </a>
    <a
      v-if="$store.getters.getUser.profile != 1 && serviceShow"
      href="#"
      class="newService d-flex align-items-center mb-1 py-0"
      @mouseenter="hoverMenu('')"
      @click.prevent="openModal()"
    >
      <div class="col-md-10">
        <p class="mb-0">Solicitar novo serviço</p>
      </div>
      <div class="col-md-2 border_shadown">
        <i class="fas fa-chevron-right"></i>
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  // props: ["servicos"],
  props: {
    serviceShow: {
      type: Boolean,
      default: true
    },
    servicos: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      newService: true,
      notification: false,
      situationModal: "Open"
    };
  },
  computed: {
    ...mapGetters(["serviceDespachanteCategoria", "getNotificationStatus"])
  },
  methods: {
    hoverMenu(val) {
      if (val.listService) {
        val.listService = val.listService.filter(
          s => s.type !== 7 && s.type !== 6
        );
        const { listService } = val;
        this.$emit("hoverMenu", this.sortArrayServices(listService));
        return;
      }
    },
    openModal() {
      $(".dropdown-menu").fadeOut(500);
      $(".sub_menu").removeAttr("style");
      $(".search_dp").fadeOut(500);
      $("header").css({ "z-index": "3" });
      $(".input_search_list").removeAttr("style");
      $(".navegation_item.active_item").removeClass("active_item");
      $("#app").removeClass("overlay_custom");
      $("#modalSolicitarServico").modal("show");
      const menu = document.querySelector("#navMenu");
      menu.classList.remove("open");
    }
  }
};
</script>

<style scoped>
p {
  white-space: initial;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #2474b2;
}
.border_shadown {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.border_shadown:before {
  /* box-shadow: -3px 0px 7px -3px rgba(33, 33, 33, 0.2);
	content: " "; */
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0;
  width: 100%;
}
i {
  float: right;
}
.dropdown-item {
  padding: 0.25rem 0.5rem;
  background: #eaf3fa;
  /* width: 283px;
	height: 40px; */
  border: 1px solid #ffffff;
  /* opacity: 0.31; */
  /* border-radius: 6px 0px 0px 6px; */
  height: 50px;
  color: #2474b2;
  font-size: 12px;
  transition: all 0.2s linear;
  direction: ltr;
}
.newService {
  background-color: #fafafa;
  padding: 0.25rem 0.5rem;
  height: 60px;
  color: #ffffff;
  font-size: 12px;
  transition: all 0.2s linear;
  direction: ltr;
}
.dropdown-item:hover {
  background: #daebf8;
  color: #f38235;
}
.menu {
  max-height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  direction: rtl;
  width: 250px;
  background: #fff;
  border-radius: 6px 0 0 6px;
}
.menu::-webkit-scrollbar {
  width: 5px;
  background: #fff;
}

.menu::-webkit-scrollbar-thumb {
  background: #2474b2;
  border-radius: 4px;
}
.menu::-webkit-scrollbar-track {
  background: #98c4e6;
}

.controller {
  font-weight: 600;
  font-size: 11px;
  line-height: 21px;
  color: #f38235;
  margin: 0 10px 0 0;
}

.notificationControllerService {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9px;
  width: 9px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
}
.notificationController {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 19px;
  width: 19px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
}
@media screen and (max-width: 767px) {
  .menu {
    width: 50% !important;
  }
  .menu a {
    height: 75px !important;
  }
  #itemInfo {
    min-width: 65%;
  }
}
@media screen and (max-width: 400px) {
  #itemInfo {
    min-width: 76%;
  }
  .menu {
    width: 41% !important;
  }
}
</style>

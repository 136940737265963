import Vue from 'vue'
import store from '../store/index'
import moment from 'moment'

Vue.prototype.getNameMeta = function (val) {
  switch (val) {
    case 'goalNet':
      return 'Meta Liquida'

    case 'goalVolume':
      return 'Meta Volume'

    case 'goalCost':
      return 'Meta Custo'
  }
}
Vue.prototype.selectPort = function (val) {
  switch (val) {
    case 1:
      return 'P'
      break
    case 2:
      return 'M'
      break
    case 3:
      return 'G'
      break

    case 4:
      return 'GG'
      break
  }
},
Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
})
// Vue.prototype.getImg = function (img) {
//     return `http://api.pontofacil.com.br/${img}?rand=${Math.random()}`
// }
Vue.prototype.getImg = function (img) {
  let urlApi = process.env.VUE_APP_ROOT_API
  const apis = ['//apiteste.pontofacil.fasters.com.br', '//api.pontofacil.com.br', '//localhost:21021']
  const urlApiClear = urlApi.replace('http:', '').replace('https:')

  const api = apis.find(s => s === urlApiClear)
  if (api != undefined) {
    return `${api}/${img}?rand=${Math.random()}`
  } else {
    return ''
  }
}
Vue.prototype.getDocument = function (doc) {
  return `//api.pontofacil.com.br/${img}`
}
Vue.prototype.sortArrayServices = function (arr) {
  return arr.sort(function (a, b) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }

    return 0
  })
}
Vue.prototype.errorImage = function (e) {
  e.target.src = require(`@/assets/image/foto_user/null.png`)
}
Vue.prototype.errorImage2 = function (e) {
  e.target.src = require(`@/assets/image/foto_user/newUser.png`)
}

Vue.prototype.getDinheiroImg = function (img) {
  return require(`@/assets/image/money/${img}.png`)
}

Vue.prototype.messageError = function (message) {
  return this.$notify({
    group: 'erros',
    type: 'error',
    text: `<i class="icon ion-close-circled"></i>${message}`
  })
}

Vue.prototype.messageSucess = function (message) {
  return this.$notify({
    group: 'erros',
    type: 'sucess',
    text: `<i class="icon ion-close-circled"></i>${message}`
  })
}

Vue.prototype.emojiScore = function (val) {
  var porcentagem = '0'
  if (val > 19) {
    porcentagem = '20'
  }
  if (val > 39) {
    porcentagem = '40'
  }
  if (val > 59) {
    porcentagem = '60'
  }
  if (val > 79) {
    porcentagem = '80'
  }
  if (val > 99) {
    porcentagem = '100'
  }
  return require(`@/assets/image/status/${porcentagem}.svg`)
}

Vue.prototype.cpfMask = function (cpf) {
  cpf = cpf.toString().replace(/[^\d]/g, '')
  // realizar a formatação...
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

Vue.prototype.dateMask = function (date) {
  if (date ?? false) {
    const dateConvert = moment(date).format('DD/MM/YYYY') ?? ''
    return dateConvert
  }

  return ''
}

Vue.prototype.phoneMask = function (phone) {
  // Remove caracteres não numéricos
  const numeroLimpo = phone.replace(/\D/g, '')

  // Verifica se é um telefone ou celular
  if (numeroLimpo.length === 10) {
    return numeroLimpo.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
  } else if (numeroLimpo.length === 11) {
    return numeroLimpo.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  } else {
    // Caso contrário, retorna o número original
    return phone
  }
}

Vue.prototype.getProfile = function (profile) {
  var profileString

  if (profile == 1) {
    profileString = 'Master'
  } else if (profile == 2) {
    profileString = 'Franqueado'
  } else {
    profileString = 'Vendedor'
  }
  return profileString
}

Vue.filter('', function (text, length, suffix) {
  return text.substring(0, length) + suffix
})
Vue.prototype.around = function (num) {
  var arredondar
  if (num) {
    arredondar = parseInt(num)
  } else {
    arredondar = 0
  }
  if (isNaN(num) == true || num == 'Infinity') {
    arredondar = 100
  }

  return arredondar
}
Vue.prototype.aroundMath = function (num) {
  return Math.round(num)
  // return num.toFixed(2)
}
Vue.prototype.aroundMathFixed = function (num) {
  // return Math.round(num)
  return num.toFixed(1)
}
Vue.prototype.valueConvert = function (value) {
  if (value === Infinity) {
    return 'R$ 0,00'
  }
  return value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  })
}

Vue.prototype.roundFn = function (num, places) {
  if (!('' + num).includes('e')) {
    return +(Math.round(num + 'e+' + places) + 'e-' + places)
  } else {
    let arr = ('' + num).split('e')
    let sig = ''
    if (+arr[1] + places > 0) {
      sig = '+'
    }

    return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + places)) + 'e-' + places)
  }
}

Vue.prototype.filterProfile = function (data, profile) {
  const value = data.filter(item => item === profile)
  console.log(profile)
  return value.toString()
}

Vue.prototype.roundFn = function (num, places) {
  if (!('' + num).includes('e')) {
    return +(Math.round(num + 'e+' + places) + 'e-' + places)
  } else {
    let arr = ('' + num).split('e')
    let sig = ''
    if (+arr[1] + places > 0) {
      sig = '+'
    }

    return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + places)) + 'e-' + places)
  }
}

Vue.prototype.formatBytes = function (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

Vue.prototype.componenteChangeStore = (Component) => {
  store.commit('changeComponent', Component)
}

Vue.prototype.valueConvertBR = function (value) {
  if (value === Infinity || isNaN(value)) {
    return '0,00'
  }

  return value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
}

Vue.prototype.getValueBR = function (value) {
  if (value === Infinity || isNaN(value)) {
    return '0.00'
  }
  return value
    .replace('R$ ', '')
    .replace(',', '.')
}

Vue.prototype.checkValueOnly = function (event) {
  if (
    event.charCode === 0 ||
        /^[\d.]+$/.test(String.fromCharCode(event.charCode))
  ) {
    return true
  } else {
    event.preventDefault()
  }
}

Vue.prototype.toast = function (msn, params) {
  params(msn, {
    position: 'bottom-right',
    timeout: 4000,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
  })
},

Vue.prototype.replaceString = function (string, value1, value2) {
  const url = string
  return url.replace(value1, value2)
}

Vue.prototype.extensaoString = function (string) {
  // console.log(string.split('.').pop())
  let item = string.split('.').pop()

  switch (item) {
    case 'jpg':
      return 1
      break
    case 'pdf':
      return 2
      break
    case 'csv':
      return 3
      break
    default:
      return 4
  }
  return string.split('.').pop()
}

Vue.prototype.scoreColor = function (val) {
  var color = '#E67979'
  if (val > 25) {
    color = '#F38235'
  }
  if (val > 51) {
    color = '#E0A638'
    // color = "#F38235";
  }
  if (val > 76) {
    // color = "#E67979";
    color = '#35B890'
  }

  return color
}
Vue.prototype.scoreColorNew = function (color) {
  return color < 1 ? "[{ offset: 0, color: '#093F69'},{ offset: 100, color: '#093F69'}]" : "[{ offset: 0, color: '#FED990'},{ offset: 50, color: '#E99637'}, { offset: 100, color: '#F38235'}]"
}
Vue.filter('invert', function (value) {
  var val = ''
  if (typeof value !== 'undefined') {
    val = value.slice().reverse()
  }

  // slice to make a copy of array, then reverse the copy
  return val
})
Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().sort((a, b) => b.port - a.port)
})

Vue.prototype.getDateBR = function (value) {
  return new Date(value)
    .toLocaleDateString('pt-BR', { timeZone: 'UTC' })
}

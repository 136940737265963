import HTTP from "@/api_system";
export default {
  state: {
    notificacao: [],
    notificacaoImagens: [],
    urgencia: "",
    timeOut: ""
  },
  mutations: {
    set_alert(state, val) {
      state.notificacao = val;
    },
    set_alertImagens(state, val) {
      state.notificacaoImagens = val;
    },
    set_alert_important(state, val) {
      state.urgencia = val;
    },
    increment(state, val) {
      state.timeOut = val;
    }
  },
  actions: {
    getAlert({ commit, getters, dispatch }) {
      HTTP.get("services/app/Alert/GetListOfAlert", {
        headers: {
          Authorization: `Bearer ${getters.getterToken}`,
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          // let newValue= [...response.data.result.item1]
          // response.data.result.item2.forEach(item => newValue.push(item))

          commit("set_alert", response.data.result.item1);
          commit("set_alertImagens", response.data.result.item2);
          //! commit("set_alert", response.data.result.item1);

          dispatch("getAlertImportant");
          dispatch("currentTime");
        })
        .catch(error => {});
    },
    getAlertImportant({ commit, getters }) {
      return new Promise((resolve, reject) => {
        HTTP.get("services/app/Alert/GetImportantAlert", {
          headers: {
            Authorization: `Bearer ${getters.getterToken}`,
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            if (response.data.result.id != null) {
              commit("set_alert_important", response.data.result);
            } else {
              commit("set_alert_important", "");
            }
          })
          .catch(error => {});
      });
    },
    currentTime({ commit, getters }) {
      return new Promise((resolve, reject) => {
        var data = "";
        setInterval(() => {
          data = moment().format();
          commit("increment", data);
        }, 1000);
      });
    }
  },
  getters: {
    notificacaoTime(state, getters) {
      var arr = [];
      let { notificacao } = state;

      notificacao.forEach(element => {
        if (
          element.startDate == null ||
          getters.getUser.profile == 1 ||
            (state.timeOut > element.startDate &&
              state.timeOut < element.endDate)
        ) {
          arr.push(element);
        }
      });

      return arr;
    },
    notifQtd(state, getters) {
      var value = 0;
      let { notificacaoImagens } = state;

      if (getters.getUser.profile == 3) {
        value += notificacaoImagens.filter(item => !item.read).length
      }

      value += getters.notificacaoTime.filter(element => !element.read).length

      return value;
    },
    urgentAlert(state, getters) {
      var obj = "";
      var element = state.urgencia;
      if (
        getters.getUser.profile != 1 &&
        (element.startDate == null ||
          (state.timeOut > element.startDate &&
            state.timeOut < element.endDate))
      ) {
        var obj = element;
      }

      return obj;
    }
  }
};

<template>
  <i :class="icone" class="mask">
    <customStyle>
      .{{icone}} {
      -webkit-mask-image: url({{dir}});
      width:{{wi}};
      height:{{hei}};
      }
      .mask{
      background-color:{{bg}};
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      }
    </customStyle>
  </i>
</template>

<script>
import customStyle from "./slotStyle";

export default {
  props: ["icone", "bg", "wi", "hei"],
  components: {
    customStyle
  },
  data() {
    return {
      dir: require(`../../assets/image/icones/${this.icone}.png`)
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style>
</style>
<template>
  <div
    class="modal fade"
    id="modalSolicitarServico"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-scrollable modal_custom"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body px-4 py-4">
          <div>
            <ValidationObserver
              tag="form"
              ref="form"
              class="col-md-12 px-0"
              @submit.prevent="sendSolicity()"
            >
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="col-md-4 mb-0 pl-0">
                    <p class="titulo_dash mb-0 d-flex align-items-center">
                      Solicitar novo serviço
                    </p>
                  </div>
                </div>
                <ValidationProvider
                  tag="div"
                  ref="sugestao"
                  class="col-md-7"
                  v-slot="{ errors, ariaMsg, classes }"
                  rules="required"
                  name="Sugestão de Serviço"
                >
                  <label class="label_default my-3">Sugestão de serviço</label>
                  <input
                    type="text"
                    class="input_default form-control"
                    v-model="superObj.name"
                    rules="required"
                    name="Sugestão de serviço"
                    :class="classes"
                  />
                </ValidationProvider>
                <div class="col-md-5">
                  <label class="label_default my-3">Link</label>
                  <input
                    type="text"
                    class="input_default form-control"
                    v-model="superObj.link"
                  />
                </div>
              </div>

              <transition-group name="list-complete" tag="div">
                <instrucao
                  :instructions.sync="superObj.instructions"
                  class="list-complete-item"
                  key="01"
                />

                <list
                  v-for="item in superObj.instructions"
                  :key="item.sequence"
                  :item="item"
                  class="list-complete-item"
                  :instructions.sync="superObj.instructions"
                />
              </transition-group>

              <div class="row mt-4">
                <div class="col-md-3 ml-auto">
                  <button
                    class="btn_default d-flex justify-content-center align-items-center"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="disabled"
                    ></span>
                    <i class="fas fa-plus-circle mr-1" v-show="!disabled"></i>
                    {{ !disabled ? `Solicitar` : "" }}
                  </button>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import instrucao from "./solicitacao/instrucao";
import list from "./solicitacao/list";

export default {
  components: { instrucao, list },
  data() {
    return {
      disabled: false,

      superObj: {
        instructions: [],
        link: "",
        name: "",
        tenantId: ""
      }
    };
  },
  methods: {
    sendSolicity() {
      if (this.superObj.name !== "") {
        this.disabled = true;
        this.superObj.tenantId = this.$store.getters.tenantRequire.tenantId;
        HTTP.post(`services/app/Service/CreateRequestService`, this.superObj, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(response => {
            this.disabled = false;
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>Solicitação enviada`
            });

            this.superObj = {
              instructions: [],
              link: "",
              name: "",
              tenantId: ""
            };
            $("#modalSolicitarServico").modal("hide");
            this.$refs.form.reset();
          })
          .catch(error => {
            this.disabled = false;
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>${error.response.data.error.message}`
            });
          });
      } else {
        this.$notify({
          group: "erros",
          type: "error",
          text: `<i class="icon ion-close-circled"></i>Informe o nome da Sugestão de serviço!`
        });
      }
    }
  }
};
</script>

<style scoped>
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}
.input_flex {
  width: 65px;
}

.fas {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}

.modal_custom {
  max-width: 590px;
}

.modal-content {
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: #2474b2;
}

::-webkit-scrollbar {
  width: 5px;
  background: #2474b2;
}

::-webkit-scrollbar-thumb {
  background: #e0a638;
  border-radius: 10px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
.input_default {
  height: 35px;
}
</style>

import HTTP from "@/api_system";
export default {
  state: {
    notices: {
      result: [],
      load: false,
      error: false
    }
  },
  mutations: {
    setNotices (state, newValue) {
      return state.notices = newValue
    }
  },
  actions: {
    GetListNotices({ commit, getters }) {
      commit('setNotices', { result: [], load: true, error: false });
      HTTP.get(`services/app/Notice/GetListNotices`, {
        headers: { Authorization: `Bearer ${getters.getterToken}` }
      })
        .then(response => {
          commit('setNotices', { result: response.data.result, load: false, error: false });
      })
        .catch(err => { commit('setNotices', { result: [], load: false, error: true }); }
      );
    },
    CreateNotice({ commit, getters }, obj) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/Notice/Create`,  obj, {
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }).then(response => {
          resolve({ msg: "Banner inserido com exito!" })
        }).catch(error => {
          console.log(error);
          if (error.response.data.error.message) {
            reject({ msg: error.response.data.error.message })
          }
          else {
            reject({ msg: 'Ops algo deu errado' })
          }
        })
      })
    },
    EditNotice({ commit, getters }, obj) {
      return new Promise((resolve, reject) => {
        HTTP.post(`services/app/Notice/Edit`,  obj, {
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }).then(response => {
          resolve({ msg: "Banner inserido com exito!" })
        }).catch(error => {
          console.log(error);
          if (error.response.data.error.message) {
            reject({ msg: error.response.data.error.message })
          }
          else {
            reject({ msg: 'Ops algo deu errado' })
          }
        })
      })
    },
    RemoveNotice({ commit, getters }, id) {
      return new Promise((resolve, reject) => {
        HTTP.delete(`services/app/Notice/Delete?id=${id}`, {
          headers: { Authorization: `bearer ${getters.getterToken}` }
        }).then(response => {
          resolve({ msg: "Banner removido com exito!" })
        }).catch(error => {
          reject({ msg: 'Ops algo deu errado' })
        })
      })
    }
  },
  getters: {
    getNotices (state) {
      return state.notices
    }
  }
}

import HTTP from '@/api_system'
import convertArray from '../../const/convertArray'
export default {
  state:{

  },
  mutations:{

  },
  actions:{
    GetListscoreByService({ commit, getters, dispatch }){
      return new Promise((resolve, reject) => {
                HTTP.get(`services/app/Dashboard/GetListscoreByService`, {
                    headers: { Authorization: `bearer ${getters.getterToken}` }
                }).then(response => {

                resolve( response.data.result)
                }).catch(error => {

                  reject({ msg: 'Ops algo deu errado' })
                })
            })
    },
    GetlistScorebycategory({ commit, getters, dispatch }){
      return new Promise((resolve, reject) => {
                HTTP.get(`services/app/Score/GetScoreByCategoryBySalesman`, {
                    headers: { Authorization: `bearer ${getters.getterToken}` }
                }).then(response => {
                  var retorno
                resolve( response.data.result)
                }).catch(error => {

                  reject({ msg: 'Ops algo deu errado' })
                })
            })
    },
     GetDashboardSallesman({ commit, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`services/app/Dashboard/GetDashboardSallesman`, {
                    headers: { Authorization: `bearer ${getters.getterToken}` }
                }).then(response => {

                  commit('set_userScore', response.data.result.user.userScore)
                  commit('set_userOverallSold', response.data.result.user.userOverallSold)
                  commit('set_tenantScore', response.data.result.tenant.tenantScore)
                  commit('set_tenantOverallSold', response.data.result.tenant.tenantOverallSold)
                  commit('set_tenantDifferenceScore', response.data.result.tenant.tenantDifferenceScore)
                  commit('set_tenantDifferenceOverall', response.data.result.tenant.tenantDifferenceOverall)
                  resolve(response.data.result)
                }).catch(error => {

                  reject({ msg: 'Ops algo deu errado' })
                })
            })
        },
  },
  getters:{
    franqueadoIndicador(state){
      return state.franqueadoIndicador
    },
    franqueadoVenderdores(state){
      return state.franqueadoVenderdores
    }
  },
}

import { render, staticRenderFns } from "./_Dropdown_despachante.vue?vue&type=template&id=b8980938&scoped=true&"
import script from "./_Dropdown_despachante.vue?vue&type=script&lang=js&"
export * from "./_Dropdown_despachante.vue?vue&type=script&lang=js&"
import style0 from "./_Dropdown_despachante.vue?vue&type=style&index=0&id=b8980938&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8980938",
  null
  
)

export default component.exports
<template>
  <div class="col-md-12">
    <ValidationObserver ref="instrucaoRef" class="row" tag="div">
      <hr class="w-100" />
      <div class="col-md-12 px-0">
        <div class="row align-items-center mb-3">
          <div class="col-md-3">
            <p class="titulo_dash mb-0">Passo a passo</p>
          </div>

          <ValidationProvider
            tag="div"
            class="col-md-5"
            v-slot="{ errors, ariaMsg, classes }"
            rules="required"
            name="Titulo"
          >
            <div class="form__group field">
              <input
                type="input"
                class="form__field"
                placeholder="Titulo"
                name="Titulo"
                id="Titulo"
                v-model="instruct.title"
              />
              <label for="Titulo" class="form__label">Titulo</label>
            </div>
            <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-md-3 pl-0">
        <div class="box_image position-relative">
          <img :src="instruct.image" class="w-100" @error="errorImage2" />
          <input
            class="btnAlteraFoto position-absolute"
            type="file"
            @change="onChoseFile"
            id="file"
            accept="image/x-png, image/gif, image/jpeg"
            placeholder="Alterar"
          />
        </div>
      </div>
      <div class="col-md-9 px-0">
        <ValidationProvider
          tag="div"
          v-slot="{ errors, ariaMsg, classes }"
          rules="required"
          name="Instruções"
        >
          <textarea
            class="w-100 mb-0 px-2 py-2"
            v-model="instruct.description"
          ></textarea>
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="d-flex">
          <div class="col-md-4 px-0">
            <span class="contador">{{ instruct.description.length }}/ 200</span>
          </div>
          <div class="col-md-4 ml-auto px-0 text-right">
            <a
              class="mb-0 p_destaque"
              @click.prevent="selectDestaque"
              :class="instruct.spotlight ? 'p_destaque_active' : 'p_destaque'"
            >
              Destaque
              <i class="fas fa-star"></i>
            </a>
          </div>
        </div>
        <div class="col-md-12 px-0 my-3">
          <button class="btn_file btn_default" @click.prevent="convertArray()">
            Adicionar
            <i class="fas fa-plus-circle ml-1"></i>
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: ["instructions"],
  data() {
    return {
      instruct: {
        image: "",
        title: "",
        description: "",
        spotlight: false,
        sequence: this.instructions.length + 1
      }
    };
  },
  mounted() {
    var self = this;
    var teste = this.instructions.length + 1;
    $("#modalSolicitarServico").on("hide.bs.modal", function(e) {
      self.instruct = {
        image: "",
        title: "",
        description: "",
        spotlight: false,
        sequence: teste
      };
    });
  },
  methods: {
    onChoseFile(event) {
      // Reference to the DOM input element
      var input = event.target;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        if (Math.round(input.files[0].size / 1024) >= 1000) {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i> Ooops, Arquivo maior que 1MB! Por favor ultilize um compressor ou selecione outro arquivo! \r Tamanho: ${Math.round(
              input.files[0].size / 1024
            )}KB`
          });

          $("#file").val("");
          return false;
        }
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData//
          this.instruct.image = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    convertArray() {
      this.$refs.instrucaoRef.validate().then(success => {
        if (success) {
          if (this.instruct.title != "" && this.instruct.description) {
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>Passo Adicionado`
            });
            var array = this.instructions;
            array.push(this.instruct);
            this.$emit("update:instructions", array);
            this.instruct = {
              image: "",
              title: "",
              description: "",
              spotlight: false,
              sequence: this.instructions.length + 1
            };
            this.$refs.instrucaoRef.reset();
          }
        }
      });
    },
    selectDestaque() {
      this.instruct.spotlight = true;
    }
  }
};
</script>

<style scoped>
.contador {
  font-size: 12px;
}
.btn_file {
  background-color: #e3e3e3;
  width: 100%;
  border: 1px solid #e3e3e3;
  font-size: 16px;
  color: #ffff;
  height: 40px;
  font-weight: 700;
}
.p_destaque {
  color: #e3e3e3;
  font-size: 16px;
  font-weight: 700;
  display: block;
  transition: all 0.2s linear;
  cursor: pointer;
}
.p_destaque:hover {
  color: #e0a638;
}
.p_destaque_active {
  color: #e0a638 !important;
  transition: all 0.2s linear;
}
hr {
  border-top: 2px solid #f4f4f4 !important;
}
.box_image {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  height: 103px;
}
.box_image img {
  height: 100%;
  object-fit: cover;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 500;
}
textarea {
  border: 1px solid #e3e3e3;
  height: 80px;
  font-size: 12px;
  color: #495057;
  font-weight: 400;
  outline: none;
}

.btn_file:hover {
  background-color: #e0a638;
}

.form__group {
  padding: 7px;
  margin-top: 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #f4f4f4;
  outline: 0;
  font-size: 14px;
  color: #495057;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 14px;
  cursor: text;
  top: 20px;
}
.fas {
  font-size: 20px;
}
.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  color: #2474b2;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #f4ab7b, #e0a638);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #e0a638;
  font-weight: 700;
}
.failed.form__field ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #dc3545 !important;
  font-weight: 700;
}
textarea.failed {
  border: 1px solid #dc3545;
}
textarea.is-valid {
  border: 1px solid #28a745;
}
.contador.is-valid {
  color: #28a745;
}
textarea.failed:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

textarea.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.failed.form__field {
  border-width: 2px;
  border-image: linear-gradient(to right, #dc3545, #dc3545);
  border-image-slice: 1;
}
.is-valid.form__field {
  border-width: 2px;
  border-image: linear-gradient(to right, #28a745, #28a745);
  border-image-slice: 1;
}
/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
.box_image::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.box_image:hover::after {
  contain: "";
  opacity: 0.8;
}

.btnAlteraFoto::-webkit-file-upload-button {
  visibility: hidden;
}
.btnAlteraFoto::after {
  content: "Alterar foto";
  color: #fff;
  outline: none;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 11px;
  opacity: 0;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}
.box_image:hover .btnAlteraFoto::after {
  opacity: 1;
}
.btnAlteraFoto {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 22;
}
</style>

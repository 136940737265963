import HTTP from '@/api_system'
export default {
    state: {
        solicitacaoLenght: []
    },
    mutations: {
      set_lenght(state, val) {
        state.solicitacaoLenght = val
      }
    },
    actions: {
      getSolicitacao({ commit, getters }) {
          return new Promise((resolve, reject) => {
              HTTP.get(
                  `services/app/Dashboard/GetLenghtOfRequest`, {
                  headers: {
                      Authorization: `Bearer ${getters.getterToken}`
                  }
              }
              ).then(response => {
                  //console.log("get solicitação: ", response.data.result);
                  commit('set_lenght', {
                      serviceEditLenght: response.data.result.serviceEditLenght,
                      servicesLenght: response.data.result.servicesLenght
                  })

                  resolve(response)
              }).catch(error => {
                  reject(error)
              });
          })
        },
    },
    getters: {
      totalSolicita(state) {
        return state.solicitacaoLenght
      }
    }
}

export default {
    state: {
        originalToken: '',
        loginClone: {},
        tenantClone: '',

    },
    mutations: {
        set_originalToken(state, val) {
            state.originalToken = val
        },
        set_loginClone(state, val) {
            state.loginClone = val
        },
        set_tenantClone(state, val) {
            state.tenantClone = val
        }
    },
    actions: {},
    getters: {
        tokenOriginal(state) {
            return state.originalToken
        },
        originalLogin(state) {
            return state.loginClone
        },
        originalTenant(state) {
            return state.tenantClone
        }
    }
}
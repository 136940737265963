import HTTP from '@/api_localservice'
import { statusPinPad } from "./enums/statusPinPad"

export default {
    state: {
      tokenZigNet: undefined,
      statusPinPad: statusPinPad.Nao_Instalado
    },
    mutations: {
      SetTokenZig(state, val) {
        state.tokenZigNet = val;
      },
      SetStatus(state, val) {
        state.statusPinPad = val;
      }
    },
    actions: {
      CreateTerminalTefLocal({ commit, getters }, obj) {
            return new Promise((resolve, reject) => {
                HTTP.post("ConfigurePinPad", obj, {
                    headers: {
                        Authorization: `Bearer ${getters.getterToken}`,
                        "Content-Type": "application/json"
                    }
                }).then(
                  response => {
                    resolve({
                      success: true,
                      msg: response
                    })
                    }
                ).catch(error => {
                    reject({ success: false, msg: error })
                });
            })
      },
      EditTerminalTefLocal({ commit, getters }, obj) {
      return new Promise((resolve, reject) => {
          HTTP.post("AlterPinPad", obj, {
              headers: {
                  Authorization: `Bearer ${getters.getterToken}`,
                  "Content-Type": "application/json"
              }
          }).then(
            response => {
              resolve({
                success: true,
                msg: response
              })
              }
          ).catch(error => {
              reject({ success: false, msg: error })
          });
      })
      },
      DeleteTerminalTefLocal({ commit, getters }) {
      return new Promise((resolve, reject) => {
          HTTP.delete("DeletePinPad", {
              headers: {
                  Authorization: `Bearer ${getters.getterToken}`,
                  "Content-Type": "application/json"
              }
          }).then(
            response => {
              resolve({
                success: true,
                msg: response.data
              })
              }
          ).catch(error => {
              reject({ success: false, msg: error })
          });
      })
      },
      GetTokenZigNet({ commit, state, dispatch, getters }) {
        HTTP.get("GetTokenPinPad",
          {
            headers: {
              Authorization: `Bearer ${getters.getterToken}`,
              "Content-Type": "application/json"
            },
            timeout: 5000
          }).then((response) => {
             commit("SetTokenZig", response.data.result.token);
             commit("SetStatus", statusPinPad.Configurado);
          }).catch(error => {
            if (error.message === "timeout of 5000ms exceeded") {
              commit("SetStatus", statusPinPad.TempoEsgotado);
            }
            else {
              commit("SetStatus", statusPinPad.Nao_Instalado);
            }
            commit("SetTokenZig", undefined);
          });
      },
      GetCheckPinPad({ commit, state, dispatch, getters }) {
        HTTP.get("CheckConectionPinPad",
          {
            headers: {
              Authorization: `Bearer ${getters.getterToken}`,
              "Content-Type": "application/json"
            },
            timeout: 5000
          }).then((response) => {
            commit("SetStatus", statusPinPad.Configurado);
          }).catch(error => {
            if (error.message === "timeout of 5000ms exceeded") {
              commit("SetStatus", statusPinPad.TempoEsgotado);
            }
            else {
              commit("SetStatus", statusPinPad.Nao_Instalado);
              commit("SetTokenZig", undefined);
            }
          });
      }
    },
    getters: {
      getTokenZigNet: state => {
        return state.tokenZigNet
      },
      getStatusPinPad(state) {
        return state.statusPinPad
      }
    }
}

<template>
  <modal
    :modalId="`modal_pedidos_abertos`"
    :modalTitle="'Pedidos em Aberto'"
    :load="false"
    :typeLg="'modal_md'"
    @closeModal="closeModal"
  >
    
    <div class="paBody">
      <div class="WarningIcon"><img src="@/assets/image/icones/Warning.svg" > 
      <h5>Você ainda tem pedidos em aberto!</h5></div>
      <div class="modal-footer">
        <div>
          <a href='/logout' class="btn btn-secondary">Fechar Caixa</a>
        </div>
        <div>
          <button type="button" @click="rotaModal('/pedidos')" id="btnPedidos" class="btn btn-primary">Ir para Pedidos</button>
        </div>
        
      </div>
    </div>
  </modal>
</template>

<script>
import modal from "@/components/modal/modalDefault.vue";



export default {
  components: {
    modal,
  },
  
  data() {
    return {
      modal_md: 'modal_lg'
    }
  },
  methods: {
    
    rotaModal(rota){
      if (this.$route.path !== this.$router.push(rota)) {
			this.$router.push(rota);
      this.closeModal();
      }

    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
}
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
    .paBody{
      padding-top: 10px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .WarningIcon{
      width: 100%;
    }
    .WarningIcon img{
      padding-right: 8px;
      float: left;
      padding-left: 20px;
      padding-top: 3px;
    }
    span{
      color: darkorange;
    }
    .btn-secondary{
      color: rgb(55, 97, 169);
      background-color: #FFFF;
      border: 2px rgb(55, 97, 169) solid;
    }
    .btn-secondary:hover{
      background-color: rgb(55, 97, 169);
      color: #FFFF;
      border-color:  rgb(55, 97, 169);
      transition: 0.7s;
      
    }
    .btn-primary{
      background-color: darkorange;
      border: none;
      float: right;
      
    }
     .btn-primary:hover{
      background-color: rgb(249, 177, 90);
      transition: 0.7s;
    }
    .modal-title{
      color: rgb(55, 97, 169);
    }
    .modal-body h5{
      color: gray;
    }
    .modal-footer{
      border-top:none;
      display: flex;
      width: 503px;
      flex-direction: row;
      justify-content: center;
    }

    

  
</style>


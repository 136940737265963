export default {
  state: {
    edit: {},
    routerFolder: 'nome da pasta',
    routerSubFolder: '',
    routerFolderFile: '',
  },
  mutations: {
    editeFile(state, newValue) {
      return (state.edit = newValue);
    },
    setRouterFolder(state, value) {
      return state.routerFolder = value;
    },
    setRouterSubFolder(state, value) {
      return state.routerSubFolder = value;
    },
    setRouterFolderFile(state, value) {
      return state.routerFolderFile = value;
    },
  },
  getters: {
    editGetFiles(state) {
      return state.edit;
    },
    getRouterFolder(state) {
      return state.routerFolder;
    },
    getRouterSubFolder(state) {
      return state.routerSubFolder;
    },
    getRouterFolderFile(state) {
      return state.routerFolderFile;
    },
  }
};

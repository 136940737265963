<template>
  <div class="dropdown-menu user_dp">
    <router-link
      class="dropdown-item d-flex align-items-center mb-1 py-0"
      :to="'/perfil'"
      exact-active-class="active"
    >
      <div class="col-md-10">Meu perfil</div>
      <div class="col-md-2 border_shadown">
        <i class="fas fa-chevron-right"></i>
      </div>
    </router-link>
    <router-link
      class="dropdown-item d-flex align-items-center my-1 py-0"
      :to="'/metas'"
      exact-active-class="active"
      v-if="$store.state.login.userLogin.profile !=1"
    >
      <div class="col-md-10">Minhas metas</div>
      <div class="col-md-2 border_shadown">
        <i class="fas fa-chevron-right"></i>
      </div>
    </router-link>
    <!-- <router-link
      :to="'/diario-de-bordo'"
      exact-active-class="active"
      class="dropdown-item d-flex align-items-center my-1 py-0"
      href="#"
    >
      <div class="col-md-10">Diário de bordo</div>
      <div class="col-md-2 border_shadown">
        <i class="fas fa-chevron-right"></i>
      </div>
    </router-link>-->
    <router-link
      class="dropdown-item d-flex align-items-center my-1 py-0"
      :to="'/administrativo'"
      v-if="$store.state.login.userLogin.profile !=3"
      exact-active-class="active"
    >
      <div class="col-md-10">Administrativo</div>
      <div class="col-md-2 border_shadown">
        <i class="fas fa-chevron-right"></i>
      </div>
    </router-link>
    <div @click="OpenPurchase" class="dropdown-item d-flex align-items-center my-1 py-0">
      <div class="col-md-10">Sair</div>
      <div class="col-md-2 border_shadown">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
    <modal_pa @closeModal="closeModal"></modal_pa>
  </div>
</template>

<script>
import modal_pa from '../../caixacp/modal_pedidos_abertos.vue';
import _User_data from '../../header/_User_data.vue';
import HTTP from "@/api_system"



export default {
  
  components: {
  modal_pa,
  _User_data
  
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    OpenModal(){
      $("#app").removeClass("overlay_custom");
      $('#modal_pedidos_abertos').appendTo("body").modal('show');
    },
    closeModal(){
      let modais = document.querySelectorAll('#modal_pedidos_abertos');
      if (modais.length > 1) modais[0].remove();

      $('#modal_pedidos_abertos').modal('hide');
    },
    OpenPurchase(){
      if(this.user.profile == 3) {
        HTTP.get('services/app/Order/GetCountUserPendingOrderDay', {
          headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
        })
        .then(response => {
          console.log(response)
          if(response.data.result > 0){
            $("header").css({ "z-index": "3" });
            $(".user_dp").fadeOut(500);
            $("#app").removeClass("overlay_custom");
            this.OpenModal()
          }
          else{
            this.$router.push("/logout");
          }
         
        })
        .catch(err => console.log(err)) 
      }else{
            this.$router.push("/logout");
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }

};
  
</script>

<style scoped>
.active {
  background-color: #f38235 !important;
  color: #ffffff !important;
}
.border_shadown {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.modal-backdrop{
  z-index: 1000!important;
}
.border_shadown:before {
  box-shadow: -3px 0px 7px -3px rgba(33, 33, 33, 0.2);
  content: " ";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0;
  width: 100%;
}
i {
  float: right;
}
.dropdown-item {
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  height: 60px;
  color: #868686;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.dropdown-item:hover {
  background-color: #f38235;
  color: #ffffff;
}
/* a {
  color: #868686;
}
.dropdown-item:hover a {
  color: #ffffff;
} */
.dropdown-menu {
  width: calc(100% - 44px);
  margin-left: 44px;
  background-color: transparent;
  padding: 0;
  margin-top: 0;
}

</style>
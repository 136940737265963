<template>
<div class="sub_menu d-flex align-items-center" id="subMenuMobile">
  <div class="container" :style="$store.getters.getUser.profile == 1 ?'margin-top:10%' : 'margin-top:0%'">
    <div class="col-md-12 mx-auto">
      <div class="row align-items-center" id="iconContContainer" :style="$store.getters.getUser.profile == 1 ?'margin-top:17%' : 'margin-top:0%'">
        <!-- <div class="col  mx-auto d-flex align-items-center "  v-if="homologa">
                          <a href="#" class="navegation_item align-items-center" @click.prevent="dropdownShowServiceMenu($event)">
                              <icons_custom icone="parcela" bg="#868686" wi="25px" hei="25px" class="mr-1" />Financeiros
                              <i class="fas fa-chevron-down ml-3" style="font-size: 10px;"></i>
                          </a>
                      </div> -->
        <!-- <div
              @click="closeToLink('/')" id="iconCont"
              :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'"
              class="navegation_item d-flex align-items-center col mx-auto"
              title="Home"
            >

              <router-link
                :to="'/'"
                exact-active-class="active"
                :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'"
                class="navegation_item d-flex align-items-center "
                title="Home"
                >Home</router-link
              >
            </div> -->

        <div @click="closeToLink('/')" id="iconCont" class="col mx-auto">
          <router-link :to="'/'" exact-active-class="active" class="navegation_item d-flex align-items-center ">Home
          </router-link>
        </div>

        <div @click="closeToLink('/pedidos')" id="iconCont" class="col mx-auto" v-if="cargo != 1">
          <router-link :to="'/pedidos'" exact-active-class="active" class="navegation_item d-flex align-items-center ">Pedidos
          </router-link>
        </div>
        <div @click="closeToLink('/despesas')" id="iconCont" class="col mx-auto" v-if="cargo == 2">
          <router-link :to="'/despesas'" exact-active-class="active" class="navegation_item d-flex align-items-center ">Despesas
          </router-link>
        </div>
        <div @click="closeToLink('/mural')" id="iconCont" class="col mx-auto">
          <router-link :to="'/mural'" exact-active-class="active" :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'" class="navegation_item d-flex align-items-center  " title="Mural">Mural</router-link>
        </div>
        <div @click="closeToLink('/notificacoes')" id="iconCont" class="col  mx-auto">
          <router-link :to="'/notificacoes'" exact-active-class="active" :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'" class="navegation_item d-flex align-items-center  position-relative" title="Notificações">Notificações
            <div v-if="notificacaoLength && $store.getters.getUser.profile != 1" class="position-absolute notification d-flex align-items-center justify-content-center">
              <span>{{ notificacaoLength }}</span>
            </div>
          </router-link>
        </div>
        <div id="iconCont">
          <div @click="closeToLink('/dados')" class="col mx-auto" id="subIconCont">
            <router-link :to="'/dados'" :class="$store.getters.getUser.profile == 1 ? ' mx-2' : ' mx-2'" exact-active-class="active" class="navegation_item d-flex align-items-center position-relative" title="Dados">Relatórios
            </router-link>
          </div>
        </div>
        <div @click="closeToLink('/arquivos')" id="iconCont" class="col  mx-auto">
          <router-link :to="'/arquivos'" exact-active-class="active" class="navegation_item mx-2 d-flex align-items-center  position-relative" title="Arquivos e Treinamentos">Arquivos e Treinamentos</router-link>
        </div>
        <div v-if="$store.getters.getUser.profile == 1" id="iconCont">
          <div style="display: flex; justify-content: center;" @click="closeToLink('/solicitacao')" class="col  mx-auto">
            <router-link :to="'/solicitacao'" exact-active-class="active" class="navegation_item mx-2 d-flex align-items-center  position-relative" title="Solicitação">
              Solicitações<div v-if="
                      $store.getters.totalSolicita &&
                        ($store.getters.totalSolicita.serviceEditLenght ||
                          $store.getters.totalSolicita.servicesLenght)
                    " class="position-absolute notificationSolici d-flex align-items-center justify-content-center">
                <span>{{
                      $store.getters.totalSolicita.serviceEditLenght +
                        $store.getters.totalSolicita.servicesLenght
                    }}</span>
              </div>
            </router-link>
          </div>
        </div>
        <div v-if="$store.getters.getUser.profile == 1" id="iconCont">
          <div @click="closeToLink('/auditoria')" style="display: flex; justify-content: center;" class="col  mx-auto">
            <router-link :to="'/auditoria'" exact-active-class="active" class="navegation_item mx-2 d-flex align-items-center  position-relative" title="Auditoria">Auditoria</router-link>
          </div>
        </div>
        <div v-if="$store.getters.getUser.profile == 1" id="iconCont">
          <div @click="closeToLink('/data')" style="display: flex; justify-content: center;" class="col  mx-auto">
            <router-link :to="'/data'" exact-active-class="active" class="navegation_item mx-2 d-flex align-items-center  position-relative" title="Data">Data</router-link>
          </div>
        </div>
        <div v-if="$store.getters.getUser.profile == 1" id="iconCont">
          <div @click="closeToLink('/banners')" style="display: flex; justify-content: center;" class="col  mx-auto">
            <router-link :to="'/banners'" exact-active-class="active" class="navegation_item mx-2 d-flex align-items-center  position-relative" title="Recados">Banners</router-link>
          </div>
        </div>
        <div @click="closeToLink('/caixa')" id="iconCont" class="col  mx-auto" v-if="cargo != 1">
          <router-link :to="'/caixa'" exact-active-class="active" class="navegation_item d-flex align-items-center ">Caixa
          </router-link>
        </div>
        <div v-if="this.$store.getters.getUser.profile !== 1" id="iconCont" @click="closeToLink('/estoque')" class="col  mx-auto">
          <router-link :to="'/estoque'" exact-active-class="active" class="navegation_item d-flex align-items-center ">Estoque
          </router-link>
        </div>
        <div @click="closeToLink('/diario-de-bordo')" id="iconCont" class="col  mx-auto">
          <router-link :to="'/diario-de-bordo'" exact-active-class="active" class="navegation_item d-flex align-items-center ">Diario de bordo
          </router-link>
        </div>
        <div @click="verifyOpenService" id="iconCont" class="col  mx-auto d-flex align-items-center ">
          <router-link to="/suporte/2" href="#" class="navegation_item align-items-center">Suporte
          </router-link>
        </div>

      </div>
    </div>
  </div>
</div>
</template>


<script>
import icons_custom from "@/components/utils/_Icon";
import {
  mapGetters
} from "vuex";
export default {
  components: {
    icons_custom,
  },
  data() {
    return {
      homologa: false,
      dataMenu: [{
        name: "Parcelamentos",
        category: null,
        id: 1,
        listInstallment: [{
            name: "Contas",
            category: null,
            id: 1
          },
          {
            name: "Detran",
            category: null,
            id: 2
          }
        ]
      }]
    };
  },
  mounted() {
    this.$store.commit("menuInstallment", this.dataMenu);
    this.homologaShow();
  },
  watch: {},
  methods: {
    verifyOpenService() {
      if (this.$store.getters.getUser.profile === 1) {
        let containerSub = document.querySelector('#subMenuMobile')
        containerSub.style.height = '240px'
      }
      this.$emit("fecha")
      this.$router.push({
        path: '/suporte/2'
      }).then()
    },
    verifyheighSubMenu() {
      this.$emit("verifyAltura")
    },

    closeToLink(rota) {
      this.$emit("fecha")
      this.$router.push({
        path: rota
      }).then()
    },

    homologaShow() {
      let urlApi = process.env.VUE_APP_ROOT_API;

      if (urlApi === "//apiteste.pontofacil.fasters.com.br") {
        this.homologa = true;
      }
    },
    dropdownShow(e) {
      //! serviços
      if (this.$route.name === "acompanhar") {
        this.$store.commit("setCloseFilter", true);
      }

      if ($(".sub_menu_dp").css("display") == "flex") {
        this.$refs.dropDown.hoverMenu("");
        e.target.classList.remove("active_item");
        $(".sub_menu_dp").fadeOut(500);
        $("header").css({
          "z-index": "3"
        });
        $(".sub_menu").removeAttr("style");

      } else {
        this.removeOverMenuActive('sub_menu_despachante', this.$refs.dropdownSup, e)
        this.removeOverMenuActive('sub_menu_sp', this.$refs.Dropdown_despachante, e)
        e.target.classList.add("active_item");

        $(".sub_menu").css({
          "z-index": "1030"
        });
        $("header").css({
          "z-index": "1031"
        });
        // $(".sub_menu_dp").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_dp").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });

      }
    },
    dropdownShowServiceMenu(e) {
      // finaceiros
      if ($(".sub_menu_installment").css("display") == "flex") {
        this.$refs.dropdownInstallment.dropdownInstallment("");
        e.target.classList.remove("active_item");
        $(".sub_menu_installment").fadeOut(500);
        $("header").css({
          "z-index": "3"
        });
        $(".sub_menu").removeAttr("style");

      } else {
        e.target.classList.add("active_item");
        $(".sub_menu").css({
          "z-index": "1030"
        });
        $("header").css({
          "z-index": "1031"
        });
        // $(".sub_menu_installment").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_installment").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });
      }
    },
    removeAll() {
      this.$refs.dropDown.hoverMenu("");
    },

    removeOverMenuActive(name, refsDrop, e) {
      if ($(`.${name}`).css("display") == "flex") {
        if (refsDrop.hoverMenu) {
          refsDrop.hoverMenu("");
        }
        const links = document.querySelectorAll('.navegation_item')
        links.forEach(item => {
          item.classList.remove('active_item')
        })

        $(`.${name}`).fadeOut(500);
        $("header").css({
          "z-index": "3"
        });
        $(".sub_menu").removeAttr("style");

      }
    },

    dropdownShowServiceDespachante(e) {

      if ($(".sub_menu_despachante").css("display") == "flex") {
        this.$refs.Dropdown_despachante.hoverMenu("");
        e.target.classList.remove("active_item");
        $(".sub_menu_despachante").fadeOut(500);
        $("header").css({
          "z-index": "3"
        });
        $(".sub_menu").removeAttr("style");
        $(".menu").css({
          left: "-155px"
        });
      } else {
        this.removeOverMenuActive('sub_menu_sp', this.$refs.Dropdown_despachante, e)
        this.removeOverMenuActive('sub_menu_dp', this.$refs.dropdownSup, e)
        e.target.classList.add("active_item");

        $(".sub_menu").css({
          "z-index": "1030"
        });
        $("header").css({
          "z-index": "1031"
        });

        $(".sub_menu_despachante").css({
          left: "140px"
        });
        // $(".sub_menu_despachante").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_despachante").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });

      }
    },
    dropdownShowSupMobile(e) {
      // suporte
      if ($(".sub_menu_sp").css("display") == "flex") {
        e.target.classList.remove("active_item");
        $(".sub_menu_sp").fadeOut(500);
        $("header").css({
          "z-index": "3"
        });
        $(".sub_menu").removeAttr("style");
      } else {

        e.target.classList.add("active_item");
        $(".sub_menu").css({
          "z-index": "1030"
        });
        $("header").css({
          "z-index": "1031"
        });
        // $(".sub_menu_sp").fadeIn(500);
        $(".search_dp").fadeOut(500);
        $(".sub_menu_sp").velocity("fadeIn", {
          duration: 500,
          display: "flex"
        });
      }
    },

    closeDropDown(element) {}
  },
  computed: {
    ...mapGetters(["getNotificationStatus"]),
    cargo() {
      return this.$store.state.login.userLogin.profile;
    },
    notificacaoLength() {
      return this.$store.getters.notifQtd;
    },
  },
  mounted() {
    if (this.$store.getters.getUser.profile === 1) {
      let subMenu = document.querySelector('.sub_menu')
      subMenu.style.height = '300px'
    }
  }
};
</script>


<style scoped>
.sub_menu {
  background: #f6f6f7;
  height: 500px;
  z-index: 2;
  margin-top: 10%;
}

#iconCont {
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px #FFFF solid;
  justify-content: center;
}

.navegation_item {
  color: #868686;
  height: 43px;
  display: inline-flex;
  font-weight: 400;
}

.navegation_item:hover {
  text-decoration: none;
  color: #2474B2;
}

.navegation_item:hover .mask {
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f38235', endColorstr='#e0a638', GradientType=0);
  /* IE6-9 */
}

.active_item {
  text-decoration: none;
}

.active_item .mask {
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f38235', endColorstr='#e0a638', GradientType=0);
  /* IE6-9 */
}

.notificationController {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 19px;
  width: 19px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
  position: absolute;
  top: -15px;
  right: 0px;
}

.notificationAccompany {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: #f38235;
  position: relative;
  top: -15px;
  right: 0px;
}

.notificationSolici {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #f38235;
  right: -21.2%;
  border: 2px solid#fff0;
  bottom: 52%;
}

.notificationSolici span {
  color: #FFFF;
  font-size: 12px;
}

.notification {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #f38235;
  right: -7%;
  top: -5%;
  border: 2px solid#fff;
}

.notification span {
  font-weight: 500;
  color: #fff;
  font-size: 8px;
}

.ml-3,
.mx-3 {
  margin-left: 0.4rem !important;
}

#iconContContainer {
  flex-direction: column !important;
}

@media screen and (max-width:767px) {
  #iconContContainer {
    flex-direction: unset !important;
  }

  #iconCont {
    background-color: #ebf6ff;
    margin: 3px;
    min-width: 48%;
    padding: 5px;
    max-width: 48%;
    border: 1px #2474B2 solid;
    border-radius: 5px;
  }

  #iconCont a {
    color: #2474B2 !important;
    font-size: 15px;
    text-align: center;
  }

  .sub_menu {
    background: #f6f6f7;
    height: auto;
    z-index: 2;
    margin-top: 10%;
  }

  #subMenuMobile {
    background: #FFFF;
  }

  #subIconCont {
    display: flex;
    justify-content: center;
  }
}
</style>

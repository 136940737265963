<template>
		<form class="login_cpf" @submit.prevent="loginStep">
			<ValidationObserver class="login_cpf">
					<div class="col-md-12 mt-3 mb-1">
						<label class="label_default mb-0">
							Qual seu
							<strong>CPF ou CNPJ ?</strong>
						</label>
					</div>
					<ValidationProvider
						v-slot="{ errors, ariaMsg, classes ,validate}"
						:rules="{ required: true, min:CPF.length <= 11 ? 11 : 14 }"
						id="loginInputContainer"
						name="CPF"
						tag="div"
						:bails="false"
						class="col-md-12 my-1 mb-3"
					>
						<the-mask
							type="text"
							id="inputLogin"
							class="input_default form-control"
							:mask="['###.###.###-##', '##.###.###/####-##']"
							@blur.native="validate($event)"
							v-model="CPF"
							placeholder="..."
							:class="classes"
							:masked="false"
						/>
						<div>
							<button
								type="submit"
								class="btn_default px-2 py-2"
								:disabled="disabled"
								id="buttonCpfLogin"
							>
								<span
									class="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
									v-show="disabled"
								></span>
								{{ !disabled ? ">" : "" }}
							</button>
						</div>
					</ValidationProvider>
			</ValidationObserver>
		</form>
</template>

<script>
import { TheMask } from 'vue-the-mask'

export default {
  components: { TheMask },

  data () {
    return {
      disabled: false,
      CPF: ''
    }
  },
  methods: {
    tenantFilter(event) {
			var inputCpfOrCnpj = event.target.value.replaceAll(/[^\d]+/g, '');
			if (inputCpfOrCnpj.length === 11 || inputCpfOrCnpj.length === 14) {
				this.disabled = true
				this.$store.dispatch('cpfForLogin', this.CPF).then(resolve => {
					if (!resolve.erro) {
						this.disabled = false
						this.$parent.gratientAnimator(
							'linear-gradient(135deg, #e0a638 0%, #f38235 100%)'
						)
						this.$router.push('/senha')
					} else {
						this.toast(resolve.message, this.$toast.error)
						this.disabled = false
					}
				})
			}
    },
		loginStep() {
			this.$refs.form.validate().then(success => {
				if (success) {
					this.disabled = true;
					this.$store.dispatch("cpfForLogin", this.CPF).then(resolve => {
						if (!resolve.erro) {
							this.disabled = false;
							this.$parent.gratientAnimator(
								"linear-gradient(135deg, #e0a638 0%, #f38235 100%)"
							);
							this.$router.push("/senha");
						} else {
							this.$notify({
								group: "erros",
								type: "error",
								text: `<i class="icon ion-close-circled"></i>${resolve.message}`
							});
							this.disabled = false;
						}
					});
				}
			});
		}
  },
  computed: {}
}
</script>

<style scoped>
#loginInputContainer {
	display: flex;
	width: 100%;
	padding-right: 0 !important;
  padding-left: 0 !important;
}
#inputLogin {
	border-radius: 7px 0 0 7px !important;
}
#buttonCpfLogin {
	height: 100%;
	border-radius: 0 7px 7px 0 !important;
}
</style>

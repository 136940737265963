import HTTP from "@/api_system";
export default {
  state: {
    tenant: '',
    tenantsAll: [],
    tenants: {
      activeTenants: [],
      desactiveTenants: [],
      load: false,
      error: false
    }
  },
  mutations: {
    set_tenant(state, val) {
      state.tenant = val;
    },
    set_tenantsAll(state, val) {
      state.tenantsAll = val;
    },
    set_tenants(state, val) {
      state.tenants = val;
    }
  },
  actions: {
    getTenants({ commit }, val) {
      var paramIdsTenants = val != null ? `?ids=${val}` : ''
    return new Promise((resolve, reject) => {
      fetch(`${process.env.VUE_APP_ROOT_API}/api/services/app/Login/GetTenantsForLogin${paramIdsTenants}`, {
          method: 'get'
      }).then(response => response.json()).then(response => {
          commit('set_tenantsAll', response.result)
          resolve(response.result)
      }).catch(error => {
          reject(error.response)
      })
    })
    },
    getAllTenants({ commit, getters }) {
      commit('set_tenants', {
        activeTenants: [],
        desactiveTenants: [],
        load: true,
        error: false
      });

      return new Promise((resolve, reject) => {
      fetch(`${process.env.VUE_APP_ROOT_API}/api/services/app/Tenant/ListOfTenants`, {
        method: "post",
        headers: {
          Authorization: `bearer ${getters.getterToken}`
        }
      }).then(response => response.json()).then(response => {
        const { activeTenants, desactiveTenants } = response.result;
        commit('set_tenants', {
          activeTenants: activeTenants,
          desactiveTenants: desactiveTenants,
          load: false,
          error: false
        });
        resolve(response.result)
      }).catch(error => {
          commit('set_tenants', {
            activeTenants: [],
            desactiveTenants: [],
            load: false,
            error: true
          });
         reject(error.response)
      })
    })
    },
    InsertSolicityGoal({ commit, getters }, obj) {
    console.log(getters.getterToken);
  return new Promise((resolve, reject) => {
    HTTP.post(`services/app/Tenant/CreateRequestGoalChange?newGoal=${obj}`, {}, {
      headers: {
        Authorization: `Bearer ${getters.getterToken}`,
        "Content-Type": "application/json"
      }
    }).then(
      response => {
        resolve({
          success: true
        })
      }
    ).catch(error => {
      console.log(error);
      reject({ success: false });
    });
  })
  },
  },
  getters: {
    validaTenent: (state) => {
        return state.tenant && !$.isEmptyObject(state.tenant)
    },
    tenantRequire: (state) => {
        return { tenantId: state.tenant.id, tenancyName: state.tenant.tenancyName }
    },
    getAllTenantsLogin: (state) => {
        return state.tenantsAll
    },
    tenantSigla: (state) => {
        var sigla
        if (state.tenant.reference != null) {
            sigla = state.tenant.reference
        } else if (state.tenant.tenancyName == 'master') {
            sigla = "Def"
        } else {
            sigla = null
        }
        return sigla
    },
    get_tenants(state) {
      return state.tenants;
    },
    get_tenant(state) {
      return state.tenant
    }
  }
}

<template>
  <div class="menu">
    <div
      :id="`menu_r_s${index}`"
      :class="`${classSolicitaces} d-flex align-items-center py-0`"
      v-for="(item, index) in listaServ"
      :key="index"
      v-if="showAcompanharDespachante(item)"
      @click="redirectRouterService(item, index)"
    >
      <div class="col-md-12 p-1">
        <p class="mb-0">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["listaServ"],
  computed: {
    user() {
      return this.$store.getters.getUser.profile;
    },
    tenant() {
      return this.$store.getters.get_tenant;
    },
    classSolicitaces() {
      if (this.listaServ.length > 1) {
        return 'dropdown-item';
      } else {
        return 'dropdown-item-solicitacoes';
      }
    }
  },
  methods: {
    redirectRouterService(item, index) {
      let route, name, id = '';
      if (Array.isArray(item)) {
        route = item[0].route;
        name = item[0].name;
        id = item[0].id;
      } else {
        route = item.route;
        name = item.name;
        id = item.id;
      }
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        this.$emit("showModalDegustacao");
      } else {
        if (route === "acompanhar" && this.user == 3 && !this.$store.getters.get_tenant.isDispatcher) {
          this.$router.push('/acompanhar/solicitacao').catch(err => {});
          return;
        }

        if (route === "acompanhar" && this.user == 2 && !this.$store.getters.get_tenant.isDispatcher ) {
          this.$router.push('/acompanhar/solicitacao').catch(err => {});
          return;
        }

        if (route === "acompanhar" && (this.user == 2 || this.user == 3) && this.$store.getters.get_tenant.isDispatcher) {
          this.$router.push('/acompanhar/solicitacao').catch(err => {});
          return;
        }

        if (route === "acompanhar" && this.user != 3) {
          this.$router .push(`/acompanhar/${this.listaServ[1].id}`).catch(err => {});
          return;
        }

        if (route === "relatorios") {
          this.$router.push(`/acompanhar/relatorios`).catch(err => {});
          return;
        }

        this.$router.push({ name: "despachante", params: { id: id } });
      }
    },
    showAcompanharDespachante(item) {
      if ((item.name == 'Acompanhar' && this.tenant.isDispatcher == false) || (item.name == 'Relatórios' && !this.tenant.isDispatcher && this.user == 3) || (item.name == 'Relatórios' && this.tenant.isDispatcher && this.user == 3)) {
        return false;
      } else {
        return true;
      }    
    },
    showAcompanharSolicitante() {
      if (this.tenant.isDispatcher == false) {
        return true;
      } else {
        return false;
      }  
    }
  }
};
</script>

<style scoped>
.dropdown-item {
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  height: 60px;
  color: #868686;
  font-size: 12px;
  white-space: normal;
  transition: all 0.2s linear;
  direction: ltr;
  cursor: pointer;
}
.dropdown-item-solicitacoes {
  position: absolute;
  top: 0;
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  height: 60px;
  color: #868686;
  font-size: 12px;
  white-space: normal;
  transition: all 0.2s linear;
  direction: ltr;
  cursor: pointer;
}
.dropdown-item:hover {
  /* background-color: #f38235; */
  color: #f38235;
}
.solicitacoes {
  position: absolute;
  bottom: 0;
  right: 0;
}
.solicitacoes > p {
  margin-bottom: 0;
}
.menu {
  max-height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  /* direction: rtl; */
  width: 160px;
  background: #fff;
  border-radius: 0 6px 6px 0;
  position: relative;
  left: -155px;
  z-index: -1;
  transition: 0.2s ease-in-out;
}
.menu::-webkit-scrollbar {
  width: 2px;
  background: #fff;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #f38235;
}
.menu::-webkit-scrollbar-track {
  background-color: #fff;
}
p {
  font-weight: 400;
  font-size: 13px;
}
@media screen and (max-width: 767px) {
  .menu {
    width: 140px !important;
  }
}
</style>

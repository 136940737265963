<template>
  <div ref="styles" style="display:none;">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "custom-styles",
  props: [],
  data() {
    return {};
  },
  mounted: function() {
    $("DOM").hide();

    let styleTag = document.createElement("style");
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
};
</script>
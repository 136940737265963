export default {
  state: {
    valuePays: undefined
  },
  mutations: {
    valorID(state, val) {
      state.valuePays = val;
    }
  },
  getters: {
    valuePays(state) {
      return state.valuePays;
    }
  }
};

export default {
  state: {
    valueCaixaFinal: 10,
    trocaComponent: false,
    caixaFechamento: []
  },
  mutations: {
    valorCx(state, val) {
      state.valueCaixaFinal = val;
    },
    trocarValuesComponent(state, value) {
      state.trocaComponent = value;
    },
    fecharCx(state, value) {
      state.caixaFechamento = value;
    },
  },
  getters: {
    valueCaixa: state => {
      return state.valueCaixaFinal;
    },
    trocarComponentClose: state => {
      return state.trocaComponent;
    },
    fecharCxValues: state => {
      return state.caixaFechamento;
    }
  }
};

import { render, staticRenderFns } from "./_menuDespachante.vue?vue&type=template&id=8cff3226&scoped=true&"
import script from "./_menuDespachante.vue?vue&type=script&lang=js&"
export * from "./_menuDespachante.vue?vue&type=script&lang=js&"
import style0 from "./_menuDespachante.vue?vue&type=style&index=0&id=8cff3226&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cff3226",
  null
  
)

export default component.exports
<template>
	<div class="dropdown-menu sub_menu_installment px-1 py-0">
		<Menu @dropdownInstallment="dropdownInstallment" :installment="installment" />
		<subMenu :listaSup.sync="listaSup" />
	</div>
</template>

<script>
	import Menu from "./Installment/_Menu.vue";
	import subMenu from "./Installment/_Sub_CategoriaInstallment.vue";
	export default {
		components: {
			subMenu,
			Menu,
		},
		data() {
			return {
				listaSup: [],
			};
		},
		mounted() {
			var self = this;
			$("#app").click(function (e) {
				if ($(e.target).is(".overlay_custom")) {
					self.listaSup = "";
				}
			});
		},
		methods: {
			dropdownInstallment(val) {
				console.log(val)
				if (val) {
					this.listaSup = val;
				} else {
					this.listaSup = "";
				}
			},
		},
		computed: {
			installment() {
				let menu = this.$store.state.installment.menuSubmenu
					// console.log(this.$store.state.installment.menuSubmenu)
				return menu.filter((item) => {
					return item.listService != "";
				});
			},
		},
	};
</script>

<style scoped>
.dropdown-menu {
	left: 14%;
	top: 60px;
	background-color: transparent;
	padding-right: 15px;
	padding-left: 15px;
	margin-top: 0;

	border: none;
}
</style>
